<template>
    <div>
        <strong class="headline">{{ $t('newsFeed.website.sessionDuration.title') }}</strong>

        <p class="text-muted">
            {{ $t('newsFeed.website.sessionDuration.content', {you: `${value} ${$tc('newsFeed.website.sessionDuration.seconds', value)}`, competitors: `${benchmark} ${$tc('newsFeed.website.sessionDuration.seconds', benchmark)}`}) }}
        </p>

        <div class="well" v-if="!value || !benchmark">
            -
        </div>

        <benchmark-chart :value="value" :benchmark="benchmark" :unit="$tc('newsFeed.website.sessionDuration.seconds', 2)" v-else></benchmark-chart>
    </div>
</template>

<style lang="scss" scoped>
        .headline {
            font-size: 18px;
            margin-top: 30px;
            display: block;
        }
</style>

<script>
    import BenchmarkChart from '@/app/news-feed/components/feed/content/sub-content/BenchmarkChart'

    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            value() {
                const details = this.activity.details

                if (!details.benchmark) {
                    return null
                }

                return Math.round(details.benchmark.value)
            },

            benchmark() {
                const details = this.activity.details

                if (!details.benchmark) {
                    return null
                }

                return Math.round(details.benchmark.benchmark)
            }
        },

        components: {
            BenchmarkChart,
        },
    }
</script>
