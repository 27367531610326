<template>
    <div class="spend-change">
        <div class="row">
            <div class="col-md-4 col-md-offset-2 g-align-center">
                <p>{{$t('newsFeed.adwords.spendChange.previousSpend')}}</p>

                <p class="number before">{{$t('newsFeed.adwords.spendChange.currency', {value: spendBefore})}}</p>
            </div>

            <div class="col-md-4 g-align-center">
                <p>{{$t('newsFeed.adwords.spendChange.currentSpend')}}</p>

                <p class="number after">{{$t('newsFeed.adwords.spendChange.currency', {value: spendAfter})}}</p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .spend-change {
        p {
            margin: 0;

            &.number {
                font-size: 24px;
            }

            &.before {
                color: #ccc;
                text-decoration: line-through;
            }

            &.after {
                color: $focusSuccess;
            }
        }
    }
</style>

<script>
    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            spendBefore() {
                if (!this.activity.details.product) {
                    return
                }

                const spendBefore = Math.round(this.activity.details.product.spendBefore)

                return this.numberFormat(spendBefore)
            },

            spendAfter() {
                if (!this.activity.details.product) {
                    return
                }

                const spendAfter = Math.round(this.activity.details.product.spendAfter)

                return this.numberFormat(spendAfter)
            }
        },

        methods: {
            numberFormat(number) {
                return this.$options.filters.numberFormat(number)
            }
        }
    }
</script>
