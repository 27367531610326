<template>
    <div class="quickview-range">
        <hr class="quickview" />

        <div class="row">

            <template v-if="!pointInTime">
                <div class="col-lg-8 col-md-12 col-sm-8 col-xs-12 text-muted range-text range-text--left">
                    <small>
                        <span class="fa fa-clock-o fa-fw"></span>
                        <span>{{from.format('L')}} - {{to.format('L')}}</span>
                    </small>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-4 col-xs-12 range-text range-text--right">
                    <small>
                        <router-link :to="cta.link" class="cta" :style="organizationColor" v-html="cta.label" />
                    </small>
                </div>
            </template>

            <template v-else>
                <div class="col-lg-8 col-md-6 col-sm-8 col-xs-12 text-muted range-text range-text--left">
                    <small>
                        <span class="fa fa-clock-o fa-fw" />
                        <span>{{pointInTime}}</span>
                    </small>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 range-text range-text--right">
                    <small>
                        <router-link :to="cta.link" class="cta" :style="organizationColor" v-html="cta.label" />
                    </small>
                </div>
            </template>

        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_mixins.scss';

    .quickview {
        margin-top: 15px;
        margin-bottom: 5px;
    }

    .cta {
        margin-top: 3px;
    }

    .range-text--right {
        text-align: right;
    }

    @media screen and (min-width: 1200px) and (max-width: 1680px) {
        .range-text {
            width: 100%;
            text-align: center;
        }
    }

    @media screen and (max-width: 1200px) {
        .range-text {
            &.range-text--left {
                text-align: left;
            }
            &.range-text--right {
                text-align: right;
            }
        }
    }

    @media screen and (max-width: 992px) {
        .range-text {
            &.range-text--left {
                text-align: left;
            }
            &.range-text--right {
                text-align: right;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .range-text {
            &.range-text--left, &.range-text--right {
                text-align: center;
            }
        }
    }
</style>

<script>
    export default {
        props: {
            from: {
                type: Object,
                required: false,
            },
            to: {
                type: Object,
                required: false,
            },
            pointInTime: null,
            cta: {
                type: Object,
                required: true
            }
        },

        computed: {
            organizationColor() {
                return {color:$org('colors.standard.link.secondary.hex')}
            }
        }
    }
</script>
