<template>
    <div class="new-product">
        <div v-if="!isSubActivity" class="headline">
            <strong>Produkt oprettet</strong>
        </div>

        <div class="product">
            <div class="logo pull-left">
                <img src="/images/feed/products/google-adwords.svg" v-if="type === 'adwords'">
                <img src="/images/feed/products/seo.svg" v-else-if="type === 'seo'">
                <img src="/images/feed/products/facebook.svg" v-else-if="type === 'facebook'">
            </div>

            <div class="name pull-left">
                <strong v-if="type === 'adwords'">Google Adwords</strong>
                <strong v-else-if="type === 'seo'">SEO</strong>
                <strong v-else-if="type === 'facebook'">Facebook annoncering</strong>
                <strong v-else-if="type === 'website'">Website</strong>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .headline {
        margin-top: 20px;
        margin-left: 15px;
    }

    .product {
        border: 1px solid #dedede;
        border-radius: 4px;
        padding: 15px;
        margin-top: 8px;

        .logo img {
            height: 30px;
        }

        .name {
            font-size: 18px;
            margin-left: 10px;
            line-height: 28px;
        }
    }
</style>

<script>
    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            type() {
                const details = this.activity.details

                if (details.product) {
                    return null
                }

                const product = details.product

                return product.type
            }
        }
    }
</script>
