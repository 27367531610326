<template>
    <div class="breakdown" v-if="hasLoaded && breakdown !== null">
        <div class="break-container" v-if="!isSubActivity && src">
            <div class="img-container">
                <img :src="src" class="img-responsive">
            </div>
        </div>

        <div class="details">
            <div class="row">
                <div class="col-md-6">
                    <p>Status</p>

                    <strong class="danger" v-if="isDown">Din hjemmeside er utilgængelig</strong>

                    <strong v-else>Din hjemmeside har haft et nedbrud</strong>

                    <small class="text-muted" v-if="identity && customer">{{identity.customer.domain}}</small>
                </div>

                <div class="col-md-6">
                    <p>Varighed af nedbrud</p>

                    <strong v-if="breakdown.end">
                        {{ breakdown.start.date | moment("from", breakdown.end.date, true)}}
                    </strong>

                    <strong v-else>
                        {{ breakdown.start.date | moment("from", "now", true) | capitalize}}
                    </strong>

                    <small class="text-muted">Fra {{breakdown.start.date | moment('Do MMMM YYYY HH:mm')}}</small>

                    <small class="text-muted" v-if="breakdown.end">til {{breakdown.end.date | moment('Do MMMM YYYY HH:mm')}}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .break-container {
        position:relative;

        .img-container {
            max-height: 300px;
            overflow: hidden;
            border-radius: 4px;
            border: 1px solid #dedede;
        }

        .break {
            position: absolute;
            top: 150px;
            left: -2px;
            width: calc(100% + 4px);

        }
    }

    .details {
        margin: 5px 0;

        .col-md-6 {
            margin-top: 15px;
        }

        p {
            margin: 0;
        }

        strong {
            font-size: 18px;
            line-height: 1;
            display: block;
            margin-bottom: -2px;
        }

        small {
            font-weight: normal;
            font-size: 10px;
        }

        .danger {
            color: #e80b0e;
        }
    }
</style>

<script>
    import { mapGetters } from 'vuex'

    const BreakdownService = require('@/services/breakdown/BreakdownService')

    export default {
        props: {
            activity: Object,

            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                hasLoaded: false,
                breakdown: null,
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer'
          }),

            src() {
                const details = this.activity.details

                if (!details.breakdown) {
                    return
                }

                const breakdown = details.breakdown

                return breakdown.screenshot
            },

            isDown() {
                const breakdown = this.breakdown

                if (!breakdown || !breakdown.end) {
                    return
                }

                return breakdown.end === null
            },
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                if (!this.activity.object) {
                    return
                }

                const id = this.activity.object.replace('Breakdown:', '')

                BreakdownService.find(id, {}, (response) => {
                    this.breakdown = response.data
                    this.hasLoaded = true
                })
            }
        }
    }
</script>
