<template>
    <div class="quickview-review range-container">
        <div class="row" style="margin: 0;" v-if="review">
            <div class="col-lg-12 relative">
                <div class="row">
                    <div class="col-lg-12">
                        <p class="text-center" style="font-weight: 500;">
                            {{$t('overview.quickview.review.headline')}}</p>

                        <transition name="slide" mode="out-in">
                            <template v-for="(review, index) in reviews">

                                <div class="new-review" @click="navigateToReview(review.id)" v-if="index === selectedReview" :key="index">
                                    <img :src="reviewServiceLogo" alt="">
                                    <h5 style="margin-top: 2px;">{{review.source.name}}
                                        <i class="fa fa-star star fa-fw" v-for="n in review.rating"/>
                                        <i class="fa fa-star-o star fa-fw" v-for="n in 5 - review.rating"/><br/>
                                    </h5>
                                    <p><i>{{review.author}}</i></p>
                                </div>

                            </template>
                        </transition>

                    </div>
                </div>
            </div>
        </div>
        <div class="row bottom-row">
            <div v-if="review">
                <quickview-range :pointInTime="createdDate" :cta="cta"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .slide-enter-active, .slide-leave-active {
        transition: opacity .5s ease-in-out, transform .5s ease-in-out;
    }

    .slide-leave-to {
        opacity: 0;
        transform: translateX(6px);
    }

    .slide-enter {
        opacity: 0;
        transform: translateX(-6px);
    }

    .slide-group {
        position: relative;
    }

    .slide-item {
        position: absolute;
    }

    .relative {
        height: 102px;
        position: relative;
    }

    .range-container {
        padding-bottom: 41px;
        position: relative;
        min-height: 159px;

        .bottom-row {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 15px;
            height: 41px;
        }
    }

    .new-review {
        width: 100%;
        background: #f9f9f9;
        margin-top: 20px;
        padding: 13px 10px 3px 10px;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
            background: #f5f5f5;
        }
    }

    img {
        width: 35px;
        height: 35px;
        display: inline-block;
        float: left;
        margin-right: 10px;
    }

    h5 {
        font-size: 14px;
        font-weight: 500;
        margin: 0;

        i {
            color: #fac725;
            font-size: 12px;
        }
    }

    p {
        font-size: 12px;
        color: #888;
    }
</style>

<script>
  import QuickviewRange from '@/app/layout/components/QuickviewRange'

  const ReviewService = require('@/services/reviews/ReviewService')

  import hasIntegration from '@/mixins/integrations/hasIntegration'
  import {mapGetters} from "vuex";

  const moment = require('moment')

  export default {
    mixins: [hasIntegration],

    data () {
      return {
        from: moment().startOf('day'),
        to: moment().startOf('day').add(1, 'day'),
        reviews: [],
        selectedReview: null,
        interval: null,
        cta: {
          label: $t('overview.quickview.review.cta'),
          link: {
            name: 'frontend.reviews'
          }
        }
      }
    },

    computed: {
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),
      review () {
        return this.reviews[this.selectedReview]
      },

      createdDate () {
        const review = this.review ? this.review : {}
        const written = review.written

        if (!written) {
          return '-'
        }

        return $t('overview.quickview.review.written') + moment(review.written.date).format('DD. MMMM YYYY')
      },

      reviewServiceLogo () {
        const review = this.review

        if (!review.source) {
          return
        }

        switch (review.source.slug) {
          case 'trustpilot':
            return '/images/review-sources/trustpilot-compact.png'
          case 'facebook':
            return '/images/review-sources/facebook-compact.png'
          case 'google-my-business':
            return '/images/review-sources/google-my-business-compact.png'
        }
      }
    },

    mounted () {
      this.$emit('state', 'loading')

      if (!this.hasHealthyIntegration(['Google Business Profile', 'Trustpilot review', 'Facebook pages'])) {
        this.$emit('state', 'failed')
        return
      }

      this.load()
    },

    destroyed () {
      clearInterval(this.interval)
    },

    methods: {
      load () {
        ReviewService.findAll({
          limit: 3,
          includes: ['source'],
          sort: [
            {
              key: 'written',
              direction: 'DESC'
            }
          ],
		  filter_groups: [{
			  filters: [{
				  key: 'customer',
				  operator: 'eq',
				  value: this.customer.id
			  }]
		  }]
        }, response => {
          const data = response.data
          this.reviews = data.rows

          if (!data.rows.length) {
            this.$emit('state', 'failed')
            return
          }

          this.$emit('state', 'success')

          this.selectedReview = 0
          this.interval = setInterval(this.nextReview, 5000)
        }, error => {
          this.$emit('state', 'failed')
        })
      },

      nextReview () {
        const reviews = this.reviews

        if (!reviews.length) {
          return
        }

        if (this.selectedReview === reviews.length - 1) {
          this.selectedReview = 0
          return
        }

        this.selectedReview++
      },

      navigateToReview (id) {
        this.$router.push({
          path: 'reviews',
          query: { reviewId: id }
        })
      }
    },

    components: {
      QuickviewRange
    }
  }
</script>
