<template>
    <div class="container-fluid top-five">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 hidden-xs">
                {{$t('newsFeed.seo.top5Keywords.keyword')}}
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 hidden-xs">
                {{$t('newsFeed.seo.top5Keywords.position')}}
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 hidden-xs">
                {{$t('newsFeed.seo.top5Keywords.change')}}
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 hidden-xs divider"></div>
        </div>

        <!-- Result -->
        <div v-for="keyword in keywords" class="row result-container">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 wrap-control">{{keyword.keyword}}</div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 current-ranking">{{keyword.current}}</div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div v-if="keyword.current > keyword.initial" class="development development--negative">
                    <span>&#9660;</span>{{Math.abs(keyword.difference)}}
                </div>

                <div v-else-if="keyword.current < keyword.initial" class="development development--positive">
                    <span>&#9650;</span>{{keyword.difference}}
                </div>

                <div v-else class="development development--neutral">
                    -
                </div>
            </div>
        </div>

        <div class="row cta-container">
            <div class="col-lg-12">
                <router-link :to="{name: 'frontend.reporting.seo'}">
                    <span>{{$t('newsFeed.seo.top5Keywords.callToAction')}}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_custom.scss';
    @import '~@/assets/scss/_mixins.scss';

    .top-five {
        padding: 20px 25px;
    }

    .result-container {
        font-weight: 300;
        margin-bottom: 20px;
    }
    .divider {
        border-bottom: 1px solid #eaeaea;
        margin: 20px 0;
    }
    .development {
        width: 60px;
        padding: 5px 0;
        border-radius: 3px;
        text-align: center;
        display: inline-block;
        font-weight: 500;
        span {
            display: inline-block;
            font-size: 10px;
            margin-right: 5px;
        }
    }
    .development--negative {
        background: $primaryAlert;
        color: $tertiaryAlert;
    }
    .development--neutral {
        background: $primaryWarning;
        color: $tertiaryWarning;
    }
    .development--positive {
        background: $primarySuccess;
        color: $tertiarySuccess;
    }
    .cta-container {
        div {
          text-align: center;
          span {
            padding: 8px 16px;
            border-radius: 3px;
            text-align: center;
            margin: 20px 0 0 0;
            color: #FFA534;
            border: 1px solid #FFA534;
            cursor: default;
            display: inline-block;
          }
        }
      }

      @media screen and (max-width: 768px) {
            .result-container {
                text-align: center;
            }
            .wrap-control {
                font-weight: 400;
            }
            .current-ranking {
                margin: 10px 0;
            }
      }

</style>

<script>
    export default {
        props: {
            activity: Object,
        },

        computed: {
            keywords() {
                if (!this.activity) {
                    return [];
                }

                const activity = this.activity

                if (!activity.details) {
                    return [];
                }

                const details = activity.details

                if (!details.keywords) {
                    return [];
                }

                return details.keywords
            }
        }
    }
</script>
