<template>
    <div>
        <div class="row head" v-if="!isSubActivity">
            <div class="col-md-6">
                <strong>{{$t('newsFeed.seo.rankingUnchanged.leftHeadline')}}</strong>
            </div>

            <div class="col-md-6 text-center hidden-xs hidden-sm">
                <strong>{{$t('newsFeed.seo.rankingUnchanged.rightHeadline', {days})}}</strong>
            </div>
        </div>

        <keyword :hasLoaded="hasLoaded" :keyword="keywordText" :url="url" :rank="rank" :rankMuted="rankMuted"></keyword>
    </div>
</template>

<style lang="scss" scoped>
    .head {
        margin-top: 20px;
        padding: 0 15px;
    }
</style>

<script>
    import Keyword from '@/app/news-feed/components/feed/content/sub-content/Keyword'

    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                keyword: null,
                ranking: null,
                hasLoaded: false,
                rankMuted: false,
            }
        },

        computed: {
            url() {
                return '-'
            },

            days() {
                const details = this.activity.details;

                if (!details.keyword || !details.keyword.days_unchanged.days) {
                  return;
                }

                return details.keyword.days_unchanged.days
            },

            rank() {
                if (!this.ranking) {
                    this.rankMuted = true
                    return $t('newsFeed.seo.rankingUnchanged.noPlacement')
                }

                if (this.ranking && this.ranking > 100) {
                    this.rankMuted = true
                    return $t('newsFeed.seo.rankingUnchanged.notInTopHundred')
                }

                return this.ranking
            },

            keywordText() {
                const details = this.activity.details

                if (!details.keyword) {
                    return ''
                }

                return details.keyword.title
            }
        },

        mounted() {
            const details = this.activity.details

            this.ranking = details.keyword.current_rank
        },


        components: {
            Keyword
        }
    }
</script>
