<template>
    <div>
        <hr v-if="isSubActivity">

        <div class="review-container" v-if="hasLoaded">
            <review :review="review"></review>
        </div>

        <div v-else>
            <p class="mock">Lorem ipsum</p>

            <p class="mock">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam et mauris blandit.</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    hr {
        margin: 15px -25px;
    }

    .review-container {
        margin: 15px 0;
    }
</style>

<script>
    import Review from '@/app/reviews/components/list/Review'

    const ReviewService = require('@/services/reviews/ReviewService')

    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                hasLoaded: false,
                review: null,
            }
        },

        computed: {
            reviewId() {
                if (!this.activity.object) {
                    return
                }

                return this.activity.object.replace('Review:', '')
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                const options = {
                    includes: ['source']
                }

                ReviewService.find(this.reviewId, options, (response) => {
                    this.review = response.data

                    this.hasLoaded = true
                })
            }
        },

        components: {
            Review
        }
    }
</script>
