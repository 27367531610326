<template>
    <div>
        <strong class="headline">Konverteringsrate fra besøg på hjemmeside til henvendelse</strong>
        <p class="text-muted">
            For hver 100 besøgende på din hjemmeside er der ca. {{value | fixedNumber(0)}} besøgende, der ringer eller sender en e-mail efterfølgende. Det er flere end gennemsnittet i din branche, hvor ca. {{benchmark | fixedNumber(0)}} ud af 100 besøgende henvender sig efter at have besøgt hjemmesiden.
        </p>

        <div class="well" v-if="!value || !benchmark">
            Kan ikke hente data
        </div>

        <benchmark-chart :value="value" :benchmark="benchmark" :unit="'%'" v-else></benchmark-chart>
    </div>
</template>

<style lang="scss" scoped>
        .headline {
            font-size: 18px;
            margin-top: 30px;
            display: block;
        }
</style>

<script>
    import BenchmarkChart from '@/app/news-feed/components/feed/content/sub-content/BenchmarkChart'

    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            value() {
                const details = this.activity.details

                if (!details.benchmark) {
                    return null
                }

                const benchmark = details.benchmark

                if (!benchmark.value) {
                    return null
                }

                return benchmark.value.toFixed(1)
            },

            benchmark() {
                const details = this.activity.details

                if (!details.benchmark) {
                    return null
                }

                const benchmark = details.benchmark

                if (!benchmark.benchmark) {
                    return null
                }

                return benchmark.benchmark.toFixed(1)
            }
        },

        components: {
            BenchmarkChart,
        },
    }
</script>
