const BaseService = require('@/services/BaseService')

const moment = require('moment')

const namespace = 'feed'

const feed = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const renderSub = (activity) => {
  activity.icon = icon(activity)
  activity.title = title(activity)
  activity.headline = headline(activity)
  activity.description = description(activity)
  activity.cta = cta(activity)

  return activity
}

const title = (activity) => {
  const details = activity.details;

  switch (activity.actor) {
    case 'seo':
      return $t('products.names.seoLong')

    case 'report':
      return $t('products.names.report')

    case 'facebook':
      return $t('products.names.facebook')

    case 'local_posts':
    case 'google':
    case 'google_my_business':
      return 'Google'

    case 'adwords':
      return $t('products.names.adwords')

    case 'product':
      return $t('newsFeed.product.title')

    case 'website':
      return $t('newsFeed.website.title')

    case 'review':
      return $t('newsFeed.reviews.title')

    case 'inquiries':
      return $t('newsFeed.inquiries.title')

    case 'custom':
      if (!details.customItem) {
        return
      }
      return details.customItem.headline
  }

  return '';
}

const headline = (activity) => {
  let headline = ''

  switch (activity.actor) {
    case 'seo':
      switch (activity.verb) {
        case 'created_keyword':
          headline = $t('newsFeed.seo.createdKeyword.headline')
          break

        case 'new_max_ranking':
          headline = $t('newsFeed.seo.newMaxRanking.headline')
          break

        case 'ranking_unchanged':
          headline = $t('newsFeed.seo.rankingUnchanged.headline')
          break

      }
      break

    case 'report':
      switch (activity.verb) {
        case 'report_created':
          headline = $t('newsFeed.report.reportCreated.headline')
          break
      }
      break

    case 'product':
      switch (activity.verb) {
        case 'product_created':
          headline = $t('newsFeed.product.productCreated.headline')
          break
      }
      break

    case 'facebook':
      switch (activity.verb) {
        case 'ad_change':
          headline = $t('newsFeed.facebook.adChange.headline')
          break

        case 'fb_spend_change':
          headline = $t('newsFeed.facebook.spendChange.headline')
          break

        case 'summary':
          headline = $t('newsFeed.facebook.summary.headline')
          break
      }
      break

    case 'adwords':
      switch (activity.verb) {
        case 'ga_spend_change':
          headline = $t('newsFeed.adwords.spendChange.headline')
          break

        case 'summary':
          headline = $t('newsFeed.adwords.summary.headline')
          break
      }
      break

    case 'google_my_business':
      switch (activity.verb) {
        case 'monthly_report':
          headline = $t('newsFeed.gmb.summary.headline')
          break
      }

      break

    case 'website':
      switch (activity.verb) {
        case 'cr_calculated':
          headline = $t('newsFeed.website.crCalculated.headline')
          break

        case 'session_duration':
          headline = $t('newsFeed.website.sessionDuration.headline')
          break

        case 'support_resolved':
          headline = $t('newsFeed.website.supportResolved.headline')
          break

        case 'breakdown_created':
          headline = $t('newsFeed.website.breakdownCreated.headline')
          break

        case 'load_time':
          headline = $t('newsFeed.website.loadTime.headline')
          break

        case 'no_ssl':
          headline = $t('newsFeed.website.noSsl.headline')
          break

        case 'no_load_time_change':
          headline = $t('newsFeed.website.noLoadTimeChange.headline')
          break

        case 'visitor_statistics':
          headline = $t('newsFeed.website.visitorStatistics.headline')
          break

        case 'visitors':
          headline = $t('newsFeed.website.visitors.headline')
          break
      }
      break

    case 'review':
      switch (activity.verb) {
        case 'review_created':
          headline = ''
          break
        case 'missing_reviews':
          headline = $t('newsFeed.inquiries.missingReviews.headline', {
            percentage: 70
          })
          break
      }
      break

    case 'inquiries':
      switch (activity.verb) {
        case 'missed_calls':
          headline = ''
          break
      }
      break

    case 'custom':
      switch (activity.verb) {
        case 'custom_item':
          headline = ''
          break
      }
      break
    case 'local_posts':
      switch (activity.verb) {
        case 'local_posts_created':
          headline = $t('newsFeed.localPosts.created.headline')
          break
      }
      break
  }

  return headline
}

const description = (activity) => {
  let description = ''

  const details = activity.details

  switch (activity.actor) {

    case 'adwords':
      switch (activity.verb) {
        case 'monthly_report':
          description = $t('newsFeed.adwords.summary.description')
          break

        case 'ga_spend_change':
            description = $t('newsFeed.adwords.spendChange.description')
            break
      }
      break

    case 'seo':
      switch (activity.verb) {
        case 'created_keyword':
          const keyword = details.keyword

          description += $t('newsFeed.seo.createdKeyword.description', {
            title: keyword.title
          })
          break

        case 'new_max_ranking':
          const newRankKeyword = details.ranking.keyword
          const rank = details.ranking.rank

          description = $t('newsFeed.seo.newMaxRanking.description', {
            newRankKeyword: newRankKeyword,
            rank: rank
          })
          break

        case 'ranking_unchanged':
          description = $t('newsFeed.seo.rankingUnchanged.description', {
            keyword: details.keyword.title,
            days: details.keyword.days_unchanged.days
          })
          break

        case 'top5_keywords':
          description = $t('newsFeed.seo.top5Keywords.description')
          break
      }

      break

    case 'report':
      switch (activity.verb) {
        case 'report_created':
          const report = details.report
          const month = moment(report.details.month.date).subtract(1, 'month')

          description = $t('newsFeed.report.reportCreated.description', {
            month: month.format('MMMM')
          })
          break
      }
      break

    case 'product':
      switch (activity.verb) {
        case 'product_created':
          description = $t('newsFeed.product.productCreated.description', {
            productName: $t('products.names.' + details.product.type)
          })
          break
      }
      break

    case 'facebook':
      switch (activity.verb) {
        case 'ad_change':
          description = $t('newsFeed.facebook.adChange.description')
          break

        case 'summary':
          description = $t('newsFeed.facebook.summary.description')
          break

        case 'fb_spend_change':
          description = $t('newsFeed.facebook.spendChange.description')
          break
      }
      break

    case 'website':
      switch (activity.verb) {
        case 'cr_calculated':
          const conversionRate = 'conversion-rate'
          const conversionRateCallsOnly = 'conversion-rate-calls-only'
          const conversionRateFormsOnly = 'conversion-rate-forms-only'

          if (!details.benchmark) {
            break
          }

          let source = ''

          switch (details.benchmark.type) {
            case conversionRate:
              source = $t('newsFeed.website.crCalculated.callsOrForms')
              break

            case conversionRateCallsOnly:
              source = $t('newsFeed.website.crCalculated.calls')
              break

            case conversionRateFormsOnly:
              source = $t('newsFeed.website.crCalculated.forms')
              break
          }

          description = $t('newsFeed.website.crCalculated.description', {
            value: details.benchmark.value,
            source: source,
            benchmark: details.benchmark.benchmark
          })
          break

        case 'session_duration':
          description = $t('newsFeed.website.sessionDuration.description', {
            value: details.benchmark.value,
            benchmark: Math.round(details.benchmark.benchmark),
            seconds: $t('newsFeed.website.sessionDuration.seconds')
          })
          break

        case 'support_resolved':
          description = $t('newsFeed.website.supportResolved.description')
          break

        case 'breakdown_created':
          description = $t('newsFeed.website.breakdownCreated.description')
          break

        case 'load_time':
          description = $t('newsFeed.website.loadTime.description')
          break

        case 'visitors':
          description = $t('newsFeed.website.visitors.description')
          break

        case 'visitor_statistics':
          description = $t('newsFeed.website.visitorStatistics.description')
          break

        case 'vulnerability_detected':
          description = $t(
            'newsFeed.website.vulnerabilityDetected.description')
          break

        case 'no_ssl':
          description = $t('newsFeed.website.noSsl.description')
          break
        case 'no_load_time_change':
          const loadTime = details.load_time
          const loadTimeObject = loadTime.now !== undefined && typeof loadTime
            .now === 'object' ? loadTime.now : loadTime
          description = $t('newsFeed.website.noLoadTimeChange.description', {
            averageTime: loadTimeObject.now,
            idealTime: loadTimeObject.ideal
          })
          break

        case 'uptime':
          description = $t('newsFeed.website.uptime.description')
          break
      }
      break

    case 'google_my_business':
      switch (activity.verb) {
        case 'monthly_report':
          description = $t('newsFeed.gmb.summary.description')
          break
      }
      break

    case 'local_posts':
      switch (activity.verb) {
        case 'local_posts_created':
          description = $t('newsFeed.localPosts.created.description')
          break
        case 'local_posts_stats':
          description = $t('newsFeed.localPosts.stats.description')
          break
      }
      break

    case 'review':
      switch (activity.verb) {
        case 'review_created':
          description = $t('newsFeed.reviews.reviewCreated.description')
          break

        case 'missing_reviews':
          description = $t('newsFeed.reviews.missingReviews.description', {
            days: details.deviation
          })
          break
      }
      break

    case 'inquiries':
      switch (activity.verb) {
        case 'missed_calls':
          description = $t('newsFeed.inquiries.missedCalls.description')
          break
      }
      break

    case 'custom':
      switch (activity.verb) {
        case 'custom_item':
          if (!details.customItem) {
            return
          }

          description = details.customItem.description
          break
      }
      break
  }

  if (!description) {
    return
  }

  return description
}

const icon = (activity) => {
  switch (activity.actor) {
    case 'seo':
      return 'pe-7s-search'

    case 'report':
      return 'pe-7s-note2'

    case 'facebook':
      return 'fa fa-facebook'

    case 'local_posts':
    case 'google_my_business':
    case 'adwords':
      return 'fa fa-google'

    case 'product':
      return 'pe-7s-albums'

    case 'website':
      return 'pe-7s-graph2'

    case 'review':
      return 'pe-7s-like'

    case 'inquiries':
      return 'pe-7s-graph3'

    case 'custom':
      return 'pe-7s-speaker'
  }
}

const cta = (activity) => {
  let buttons = []

  switch (activity.verb) {
    // Report
    case 'report_created':
      buttons.push({
        title: $t('newsFeed.report.reportCreated.button'),
        class: 'btn btn-sm btn-primary',
        historyId: activity.details.report.historyId,
        path: ''
      })
      break
  }

  return buttons
}

const image = (activity) => {
  return activity.image
}

const activities = (feed, options, cb, errorCb) => {
  BaseService.get(namespace + '/' + feed + '/activities', options, cb,
    errorCb)
}

export {
  activities,
  renderSub,
  feed
}
