<template>
    <div class="quickview-competitor range-container" v-if="keyword">
        <div class="row" style="margin: 0;">
            <div class="col-lg-12 text-center">
                <p style="font-size: 12px; color: #888; font-weight: 500;">
                    {{$t('overview.quickview.keywords.headline')}}</p>
                <h5 style="margin: 0 0 10px 0; font-size: 20px;">{{keyword.keyword}}</h5>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center" style="border-right: 1px solid #eee;">
                {{$t('overview.quickview.keywords.change')}}
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center" style="border-right: 1px solid #eee;">
                {{$t('overview.quickview.keywords.position')}}
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
                {{$t('overview.quickview.keywords.goto')}}
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
                <span class="historical-change"
                      :style="organizationCaretColor">
                    <i class="fa"
                       :class="[ keyword.deviation > 0 ? 'fa-caret-up up' : 'fa-caret-down down' ]"
                       :style="organizationCaretColor"
                       v-if="keyword.deviation !== 0"/>
                    {{keyword.deviation}}
                </span>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
                {{keyword.currentRank}}
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
                <a :href="keyword.searchUrl" rel="noopener noreferrer" target="_blank">
                    <i class="fa fa-link external-link"/>
                </a>
            </div>

        </div>
        <div class="row bottom-row">
                <quickview-range :pointInTime="$t('overview.today')" :cta="cta"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .range-container {
        padding-bottom: 41px;
        position: relative;
        min-height: 159px;

        .bottom-row {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 41px;
        }

        .historical-change {
            font-size: 14px;
            display: block;
        }
    }

    h5 {
        font-size: 40px;
        margin-bottom: 5px;
        font-weight: 500;
    }

    p {
        font-size: 14px;
        line-height: 16px;
    }
</style>

<script>
  import QuickviewRange from '@/app/layout/components/QuickviewRange'

  import * as KeywordService from '@/services/seo/KeywordService'
  import {mapGetters} from "vuex";

  const moment = require('moment')

  export default {
    data () {
      return {
        from: moment().startOf('day'),
        to: moment().startOf('day').add(1, 'day'),
        keyword: null,
        cta: {
          label: $t('overview.quickview.keywords.cta'),
          link: {
            name: 'frontend.reporting.seo'
          }
        }
      }
    },

    computed: {
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),

      organizationCaretColor () {
        return this.keyword.deviation >= 0 ?
          { color: $org('colors.standard.statuses.positive.focus') } :
          { color: $org('colors.standard.statuses.negative.focus') }
      }
    },

    mounted () {
      this.$emit('state', 'loading')

      this.load()
    },

    methods: {
      load () {
        KeywordService.findLatest({
          limit: 10,
          includes: [
            'rankings'
          ],

			filter_groups: [{
				filters: [{
					key: 'customer',
					operator: 'eq',
					value: this.customer.id
				}]
			}]
        }, ({data}) => {

          this.keyword = data

          this.$emit('state', 'success')
        }, () => {
          this.$emit('state', 'failed')
        })
      }
    },

    components: {
      QuickviewRange
    }
  }
</script>
