<template>
  <div class="container-fluid">
      <div v-if="insights" v-for="(metric, index) in metrics" class="row">
        <div v-for="(value, current) in metric" :class="classForMetric(current, metric)" style="text-align: center;">
            <i :class="iconForKey(value.icon)"></i>

            <span class="body__value">{{insights[value.metric] | numberFormat}}</span>

            <span class="body__details">{{translationForMetric(value.metric)}}</span>
        </div>

        <div v-if="index < metrics.length - 1" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 divider"></div>
      </div>

      <div class="row cta-container">
          <div class="col-lg-12">
              <span>{{translationForKey('cta', {})}}</span>
          </div>
      </div>
  </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .container-fluid {
      padding: 35px 25px;
    }
    .body__icon {
        font-size: 32px;
        color: $focusPositive;
        margin-bottom: 10px;
    }
    .body__value {
        font-size: 24px;
        font-weight: 300;
    }
    .body__details {
        font-size: 12px;
        color: #888888;
    }
    span {
        display: block;
    }
    .divider {
        border-bottom: 1px solid #eaeaea;
        margin: 40px 0;
    }
    .cta-container {
        div {
          text-align: center;
          span {
            padding: 8px 16px;
            border-radius: 3px;
            text-align: center;
            margin: 40px 0 0 0;
            color: #FFA534;
            border: 1px solid #FFA534;
            cursor: default;
            display: inline-block;
          }
        }
      }

      @media screen and (max-width: 768px) {
      .content-container {
        margin-bottom: 40px;
      }
      .divider {
        margin: 0 0 40px 0;
      }
    }


</style>

<script>
    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                metrics: [
                    [
                        { icon: 'search', metric: 'views_search' },
                        { icon: 'map', metric: 'views_maps' },
                        { icon: 'car', metric: 'actions_driving_directions' }
                    ],
                    [
                        { icon: 'mouse', metric: 'actions_website' },
                        { icon: 'star', metric: 'reviews' }
                    ],
                ]
            }
        },

        methods: {
            classForMetric(current, metric) {
                const metricCount = metric.length

                let classNames = 'col-lg-4 col-md-4 col-sm-4 col-xs-12 content-container'

                if (metricCount === 2 && current === 0) {
                    classNames += 'col-lg-offset-2 col-md-offset-2 col-sm-offset-2'
                }

                return classNames
            },

            translationForMetric(metric) {
                return this.translationForKey(`metrics.${metric}`, {})
            },

            translationForKey(key, args) {
                return $t(`newsFeed.gmb.summary.${key}`, args)
            },

            iconForKey(key) {
                return `body__icon pe-7s-${key}`
            },

            filterInsights(details) {
                const insights = []

                insights['reviews'] = details.totalReviews

                if (!details.insights || !details.insights.locationMetrics) {
                    return
                }

                const metrics = details.insights.locationMetrics[0]

                if (!metrics || metrics === null) {
                    return
                }

                const values = metrics.metricValues

                if (!values || values === null) {
                    return
                }

                values.forEach(value => {
                    if (value.metric !== null) {
                        insights[value.metric.toLowerCase()] = value.totalValue.value
                    }
                })

                return insights
            }
        },

        computed: {
            insights() {
                const activity = this.activity

                if (!activity || activity === null) {
                    return
                }

                const details = activity.details

                if (!details || details === null) {
                    return
                }

                return this.filterInsights(details)
            }
        },
    }
</script>
