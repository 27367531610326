<template>
    <div class="custom-item-activity">
        <custom-item :image="customItem.image" :headline1="customItem.subHeadline1" :description1="customItem.subDescription1" :headline2="customItem.subHeadline2" :description2="customItem.subDescription2" :headline3="customItem.subHeadline3" :description3="customItem.subDescription3" :linkUrl="customItem.linkUrl" :linkText="customItem.linkText" v-if="hasLoaded"></custom-item>

        <div class="text-center spinner" v-else>
            <i class="fa fa-circle-o-notch fa-spin" ></i>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .spinner {
        margin: 150px auto;
    }
</style>

<script>
    import CustomItem from '@/app/news-feed/components/feed/content/custom-item/CustomItem'

    const CustomItemService = require('@/services/stream/CustomItemService')

    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                hasLoaded: false,
                customItem: null,
            }
        },

        computed: {
            customItemId() {
                return this.activity.object.replace('CustomItem:', '')
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                CustomItemService.find(this.customItemId, {}, (response) => {
                    this.customItem = response.data

                    this.hasLoaded = true
                })
            }
        },

        components: {
            CustomItem
        }
    }
</script>
