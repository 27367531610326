<template>
	<div class="quickview-analytics-visitors range-container">
		<div class="row" style="margin: 0;">
			<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 relative" style="padding: 0;">
				<div class="chart"></div>
			</div>

			<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 relative text-alignment">
                <span class="chart-text">
                    <h5>{{ total | numberFormat }}</h5>
                    <p><small v-html="$t('overview.quickview.analyticsVisitors.visits', {daily: dailyTotal})"/></p>
                </span>
			</div>
		</div>

		<div class="row bottom-row">
			<div class="col-lg-12" style="padding: 0;">
				<quickview-range :pointInTime="$t('overview.quickview.analyticsVisitors.latest30Days')" :cta="cta"/>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.quickview-analytics-visitors .chart {
	position: relative;
	z-index: 10;

	* {
		overflow: visible !important;
	}
}
</style>

<script>
import QuickviewRange from '@/app/layout/components/QuickviewRange'
import AnalyticsService from '@/services/_app/google-analytics/AnalyticsService'
import hasIntegration from '@/mixins/integrations/hasIntegration'
import trafficSourcesMixin from '@/app/traffic-sources/traffic-sources.mixin'
import {getTranslatedErrorMessage} from "@/services/response/ResponseService";

const HighCharts = require('highcharts')

const moment = require('moment')

export default {
	mixins: [hasIntegration, trafficSourcesMixin],

	data() {
		return {
			chart: null,
			serie: null,
			from: moment().subtract(30, 'days'),
			to: moment().startOf('day').add(1, 'day'),
			dailyTotal: 0,
			cta: {
				label: $t('overview.quickview.analyticsVisitors.cta'),
				link: {
					name: 'frontend.reporting.visitors'
				}
			},
			pointInTime: moment().format('DD. MMMM YYYY')
		}
	},

	computed: {
		total() {
			const serie = this.serie

			if (!this.serie) {
				return 0
			}

			const data = serie.data

			let total = 0

			for (let i = 0; i < data.length; i++) {
				total += data[i][1]
			}

			return total
		}
	},

	mounted() {
		if (!this.hasHealthyIntegration('Google analytics')) {
			this.$emit('state', 'failed')
			return
		}

		this.$emit('state', 'loading')

		this.load()
	},

	methods: {
		loadSeries() {
			try {
				const from = moment().startOf('day')
				const {rows} = this.getSessionsWithSources();

				if (!rows.length) {
					this.$emit('state', 'failed')
					return
				}

				this.$emit('state', 'success')

				this.serie = {
					colors: rows.map(row => this.colorForMedium(row.sourceMedium)),
					type: 'pie',
					data: rows.map(row => [row.sourceMedium, row.sessions]),
					innerSize: '65%',
					size: '300%',
					name: 'Visitor Percentage'
				}

				this.render()

			} catch (e) {
				this.$emit('state', 'failed')
			}
		},

		loadDailyTotal() {
			try {
				const from = moment().startOf('day')
				const {rows} = this.getSessionsWithSources();

				rows.forEach(row => {
					this.dailyTotal += row.sessions
				})

			} catch (e) {
				this.$emit('state', 'failed')
			}
		},

		async getSessionsWithSources() {
			let response = await AnalyticsService.sessionsWithSources(this.from, this.to, null);
			if (response?.success === false) {
				this.$swal({
					type: 'error',
					text: getTranslatedErrorMessage(response.errorCode)
				});
				response = response?.errorMsg
			}

			return response;
		},

		load() {
			this.loadSeries()
			this.loadDailyTotal()
		},

		render() {
			const el = this.$el.querySelector('.chart')
			const chart = this.chart

			const that = this

			if (!chart) {
				this.chart = new HighCharts.Chart(el, {
					credits: false,
					chart: {
						type: 'pie',
						height: 85,
						dataLabels: {
							enabled: false
						}
					},
					title: {
						text: ''
					},
					plotOptions: {
						series: {
							marker: {
								enabled: false
							}
						},
						pie: {
							dataLabels: {
								enabled: false
							}
						}
					},
					tooltip: {
						headerFormat: null,
						pointFormatter: function () {
							return `${that.translate(this.name)}: <b>${this.percentage.toFixed(1)}%</b>`
						}
					},
					series: [this.serie]
				})

				return
			}

			chart.series[0].setData(this.serie.data)
		}
	},

	components: {
		QuickviewRange
	}
}
</script>
