<template>
    <div class="activity">
        <template v-if="activity.actor === 'seo'">
            <created-keyword v-if="activity.verb === 'created_keyword'" :activity="activity" :isSubActivity="isSubActivity"></created-keyword>
            <new-max-ranking v-else-if="activity.verb === 'new_max_ranking'" :activity="activity" :isSubActivity="isSubActivity"></new-max-ranking>
            <ranking-unchanged v-else-if="activity.verb === 'ranking_unchanged'" :activity="activity" :isSubActivity="isSubActivity"></ranking-unchanged>
            <top5-keywords v-else-if="activity.verb === 'top5_keywords'" :activity="activity"></top5-keywords>
        </template>

        <template v-if="activity.actor === 'report'">
                <default :activity="activity"></default>
        </template>

        <template v-if="activity.actor === 'product'">
            <product-created :activity="activity" v-if="activity.verb === 'product_created'" :isSubActivity="isSubActivity"></product-created>
        </template>

        <template v-if="activity.actor === 'facebook'">
            <ad-change :activity="activity" v-if="activity.verb === 'ad_change'" :isSubActivity="isSubActivity"></ad-change>
            <spend-change-facebook :activity="activity" v-else-if="activity.verb === 'fb_spend_change'" :isSubActivity="isSubActivity"></spend-change-facebook>
            <facebook-ads-summary :activity="activity" v-else-if="activity.verb === 'summary'" :isSubActivity="isSubActivity"></facebook-ads-summary>
        </template>

        <template v-if="activity.actor === 'adwords'">
            <spend-change-adwords :activity="activity" v-if="activity.verb === 'ga_spend_change'" :isSubActivity="isSubActivity"></spend-change-adwords>
            <adwords-summary :activity="activity" v-if="activity.verb === 'monthly_report'" :isSubActivity="isSubActivity"></adwords-summary>
        </template>

        <template v-if="activity.actor === 'website'">
            <wordpress-threat :activity="activity" v-if="activity.verb === 'vulnerability_detected'" :isSubActivity="isSubActivity"></wordpress-threat>
            <session-duration :activity="activity" v-if="activity.verb === 'session_duration'" :isSubActivity="isSubActivity"></session-duration>
            <conversion-rate :activity="activity" v-else-if="activity.verb === 'cr_calculated'" :isSubActivity="isSubActivity"></conversion-rate>
            <support-resolved :activity="activity" v-else-if="activity.verb === 'support_resolved'" :isSubActivity="isSubActivity"></support-resolved>
            <breakdown-created :activity="activity" v-else-if="activity.verb === 'breakdown_created'" :isSubActivity="isSubActivity"></breakdown-created>
            <load-time :activity="activity" v-else-if="activity.verb === 'load_time' || activity.verb === 'no_load_time_change'" :isSubActivity="isSubActivity"></load-time>
            <no-ssl :activity="activity" v-else-if="activity.verb === 'no_ssl'" :isSubActivity="isSubActivity"></no-ssl>
            <visitor-statistics :activity="activity" v-else-if="activity.verb === 'visitor_statistics'" :isSubActivity="isSubActivity"></visitor-statistics>
            <visitors :activity="activity" v-else-if="activity.verb === 'visitors'" :isSubActivity="isSubActivity"></visitors>
            <uptime :activity="activity" v-else-if="activity.verb === 'uptime'" :isSubActivity="isSubActivity"></uptime>
        </template>

        <template v-if="activity.actor === 'review'">
            <review-created :activity="activity" v-if="activity.verb === 'review_created'" :isSubActivity="isSubActivity"></review-created>
            <missing-reviews-item :activity="activity" v-else-if="activity.verb === 'missing_reviews'" :isSubActivity="isSubActivity"></missing-reviews-item>
        </template>

        <template v-if="activity.actor === 'google_my_business'">
            <google-my-business-summary :activity="activity" v-if="activity.verb === 'monthly_report'" :isSubActivity="isSubActivity"></google-my-business-summary>
        </template>

        <template v-if="activity.actor === 'inquiries'">
            <missed-calls :activity="activity" v-if="activity.verb === 'missed_calls'" :isSubActivity="isSubActivity"></missed-calls>
        </template>

        <template v-if="activity.actor === 'custom'">
            <custom-item-activity :activity="activity" v-if="activity.verb === 'custom_item'" :isSubActivity="isSubActivity"></custom-item-activity>
        </template>

        <template v-if="activity.actor === 'local_posts'">
            <local-post-stats v-if="activity.verb === 'local_posts_stats'" :activity="activity"></local-post-stats>
            <default v-else :activity="activity"></default>
        </template>

        <div class="clearfix"></div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import Card from '@/app/shared/components/Card'
import Default from './content/Default'
import CreatedKeyword from './content/seo/CreatedKeyword'
import NewMaxRanking from './content/seo/NewMaxRanking'
import RankingUnchanged from './content/seo/RankingUnchanged'
import ProductCreated from './content/product/ProductCreated'
import AdChange from './content/facebook/AdChange'
import SessionDuration from './content/website/SessionDuration'
import ConversionRate from './content/website/ConversionRate'
import SpendChangeFacebook from './content/facebook/SpendChange'
import SpendChangeAdwords from './content/adwords/SpendChange'
import SupportResolved from './content/website/SupportResolved'
import ReviewCreated from './content/review/ReviewCreated'
import BreakdownCreated from './content/website/BreakdownCreated'
import LoadTime from './content/website/LoadTime'
import NoSsl from './content/website/NoSsl'
import MissedCalls from './content/inquiries/MissedCalls'
import CustomItemActivity from './content/custom-item/Activity'
import AdwordsSummary from './content/adwords/AdwordsSummary'
import GoogleMyBusinessSummary from './content/gmb/GoogleMyBusinessSummary'
import FacebookAdsSummary from './content/facebook/FacebookAdsSummary'
import VisitorStatistics from './content/website/VisitorStatistics'
import Visitors from './content/website/Visitors'
import MissingReviewsItem  from './content/review/MissingReviewsItem'
import WordpressThreat from './content/website/WordpressThreat'
import Top5Keywords from './content/seo/Top5Keywords'
import Uptime from './content/website/Uptime'
import LocalPostStats from './content/google/LocalPostStats'

export default {
    props: {
        activity: Object,
        isSubActivity: {
            type: Boolean,
            default: false,
        }
    },

    components: {
        Card,
        Default,
        CreatedKeyword,
        NewMaxRanking,
        RankingUnchanged,
        ProductCreated,
        AdChange,
        SessionDuration,
        ConversionRate,
        SpendChangeFacebook,
        SpendChangeAdwords,
        SupportResolved,
        ReviewCreated,
        BreakdownCreated,
        MissedCalls,
        LoadTime,
        NoSsl,
        CustomItemActivity,
        AdwordsSummary,
        GoogleMyBusinessSummary,
        FacebookAdsSummary,
        VisitorStatistics,
        Visitors,
        MissingReviewsItem,
        WordpressThreat,
        Top5Keywords,
        Uptime,
        LocalPostStats
    }
}
</script>
