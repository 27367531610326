<template>
    <div class="asideview-adwords-ads" v-if="hasLoaded">
        <div class="row">
            <div class="col-lg-12" style="margin-bottom: 20px;">
                <i class="fa fa-google" style="display: inline; float: left; margin-right: 10px;"/>
                <h6>{{$t('overview.quickview.adWords.headline')}}</h6>
                <p>{{$t('overview.quickview.adWords.description')}}</p>
            </div>

            <div class="col-lg-3 text-center value-col">
                <strong>{{$t('overview.quickview.adWords.reach')}}</strong><br />
                {{values.impressions | numberFormat}}
            </div>

            <div class="col-lg-3 text-center value-col">
                <strong>{{$t('overview.quickview.adWords.clicks')}}</strong><br />
                {{values.clicks | numberFormat}}
            </div>

            <div class="col-lg-3 text-center value-col">
                <strong>{{$t('overview.quickview.adWords.conversions')}}</strong>
                <br />
                {{ (values.ctr * 100) | fixedNumber(2) | numberFormat }}%
            </div>

            <div class="col-lg-3 text-center value-col">
                <strong>{{$t('overview.quickview.adWords.cost')}}</strong><br />
                {{(values.costMicros  / 1000000) | fixedNumber(2) | numberFormat}} {{$t('overview.quickview.adWords.affix')}}
            </div>
        </div>

        <div class="row bottom-row">
            <div class="col-lg-12">
                <quickview-range :from="from" :to="to" :cta="cta" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_mixins.scss';

    .value-col {
        @include ellipsis;
    }
</style>

<script>
    import QuickviewRange from '@/app/layout/components/QuickviewRange'

    const GoogleAdsService = require('@/services/google-ads/GoogleAdsService')

    import hasIntegration from '@/mixins/integrations/hasIntegration'

    const moment = require('moment')

    export default {
        mixins: [hasIntegration],

        data() {
            return {
                from: moment().subtract(7, 'days'),
                to: moment().startOf('day'),
                values: null,
                integrationId: null,
                cta: {
                    label: $t('overview.quickview.adWords.cta'),
                    link: {
                        name: 'frontend.googleAds.ads'
                    }
                }
            }
        },

        computed: {
            hasLoaded() {
                return this.values && Object.keys(this.values).length
            }
        },

        mounted() {
            this.$emit('state', 'loading')

            if (!this.hasHealthyIntegration('Google Ads')) {
                this.$emit('state', 'failed')
                return
            }

            this.integrationId = this.getIntegration('Google Ads').id

            this.load()
        },

        methods: {
            load() {
				this.$emit('state', 'failed')
				return

                GoogleAdsService.accountOverviewPerformance(this.from, this.to, this.integrationId, response => {

                    const data = response.data
                    const clicks = data[0].metrics.clicks
                    const costMicros = data[0].metrics.costMicros
                    const impressions = data[0].metrics.impressions
                    const ctr = data[0].metrics.ctr

                    const insights = {
                        impressions: impressions,
                        clicks: clicks,
                        ctr: ctr,
                        costMicros: costMicros
                    }

                    this.values = insights

                    this.$emit('state', 'success')
                }, error => {
                        console.log("error: ", error)
                        this.$emit('state', 'failed')
                    }
                )
            }
        },

        components: {
            QuickviewRange
        }
    }
</script>
