<template>
    <div class="container-fluid">
        <div v-if="details" v-for="(metric, index) in metrics" class="row">
            <div v-for="value in metric" class="col-lg-4 col-md-4 col-sm-4 col-xs-12 content-container" style="text-align: center;">
                <i :class="iconForKey(value.icon)"></i>

                <span class="body__value">
                  <template v-if="value.metric === 'averagePages'">{{details[value.metric] | fixedNumber(1)}}</template>
                  <template v-else>{{details[value.metric] | numberFormat}}</template>
                </span>

                <span class="body__details">{{translationForMetric(value.metric)}}</span>
            </div>

            <div v-if="index < metrics.length - 1" class="col-lg-8 col-lg-push-2 divider"></div>
        </div>

        <div class="row cta-container">
            <div class="col-lg-12">
                <router-link :to="{name: 'frontend.reporting.seo'}">
                    <span>{{this.translationForKey('cta', {})}}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .container-fluid {
      padding:35px 25px;
    }
    .body__icon {
        font-size: 32px;
        color: $focusPositive;
        margin-bottom: 10px;
    }
    .body__value {
        font-size: 24px;
        font-weight: 300;
    }
    .body__details {
        font-size: 12px;
        color: #888888;
    }
    span {
        display: block;
    }
    .divider {
        border-bottom: 1px solid #eaeaea;
        margin: 40px 0;
    }
    .cta-container {
      div {
        text-align: center;
        span {
          padding: 8px 16px;
          border-radius: 3px;
          text-align: center;
          margin: 30px 0 0 0;
          color: #FFA534;
          border: 1px solid #FFA534;
          cursor: default;
          display: inline-block;
        }
      }
    }

  @media screen and (max-width: 1200px) {
    .content-container {
      margin-bottom: 20px;
    }
  }

</style>

<script>
    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                metrics: [
                    [
                        { icon: 'phone', metric: 'mobileTablet' },
                        { icon: 'clock', metric: 'averageSession' },
                        { icon: 'bookmarks', metric: 'averagePages' }
                    ],
                ]
            }
        },

        methods: {
            translationForMetric(metric) {
                return this.translationForKey(`metrics.${metric}`, {})
            },

            translationForKey(key, args) {
                return $t(`newsFeed.website.visitorStatistics.${key}`, args)
            },

            iconForKey(key) {
                return `body__icon pe-7s-${key}`
            },
        },

        computed: {
            details() {
                const activity = this.activity

                if (!activity || activity === null) {
                    return
                }

                const details = activity.details

                if (!details || details === null) {
                    return
                }

				if (activity.details.averageSession) {
					activity.details.averageSession = Math.round(activity.details.averageSession)
				}

                return details
            }
        },
    }
</script>
