<template>
    <div class="quickview-competitor range-container" v-if="competitor">
        <div class="row" style="margin: 0;">
            <div class="col-lg-12">
                <p class="domain text-center" style="font-weight: 500;">{{competitor.domain}}</p>
            </div>

            <div class="col-xs-6 text-center" style="height: 102px;">
                <h5 class="transition">{{metrics.organicTraffic | numberFormat}}</h5>

                <p>
                    <small>
                        {{$t('overview.quickview.customer.organicTraffic')}}<br />
                        <span class="text-muted">{{$t('overview.quickview.competitor.keywordsDescription')}}</span>
                    </small>
                </p>
            </div>

            <div class="col-xs-6 text-center" style="height: 102px;">
                <h5 class="transition">{{metrics.organicKeywords | numberFormat}}</h5>

                <p>
                    <small>
                        {{$t('overview.quickview.customer.organicKeywords')}}<br />
                        <span class="text-muted">{{$t('overview.quickview.competitor.trafficDescription')}}</span>
                    </small>
                </p>
            </div>
        </div>
        <div class="row bottom-row">
                <quickview-range :pointInTime="$t('overview.today')" :cta="cta" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_custom.scss';

    .range-container {
        padding-bottom: 41px;
        position: relative;
        min-height: 159px;
        .bottom-row {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 41px;
        }
    }

    .domain {
        font-size: 12px;
        color: #888;
        margin: 0;
    }

    h5 {
        font-size: 30px;
        margin-bottom: 5px;
        font-weight: 500;
    }

    p {
        font-size: 14px;
        line-height: 16px;
    }
</style>

<script>
    import QuickviewRange from '@/app/layout/components/QuickviewRange'

    import CompetitorService from '@/services/competitors/CompetitorService'
    import CompetitorMetricService from '@/services/competitors/CompetitorMetricService'

    import { mapGetters } from 'vuex'

    const moment = require('moment')

    export default {
        data() {
            return {
                from: moment().subtract(30, 'days'),
                to: moment().startOf('day'),
                competitor: null,
                metrics: {
                    organicTraffic: null,
                    organicKeywords: null
                },
                cta: {
                    label: $t('overview.quickview.competitor.cta'),
                    link: {
                        name: 'frontend.reporting.competitors'
                    }
                }
            }
        },

		computed: {
			...mapGetters('identity', {
				identity: 'getIdentity'
			}),
			...mapGetters('customer', {
				customer: 'getCustomer'
			})
		},

        mounted() {
            this.$emit('state', 'loading')

            this.load()
        },

        methods: {
            load() {
                const options = {
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'isRelevant',
                                    operator: 'eq',
                                    value: true
                                }
                            ]
                        },
						{
						filters: [{
								key: 'customer',
								operator: 'eq',
								value: this.customer.id
							}]
						}
					]
                }

                CompetitorService.findAll(options, response => {
                    const data = response.data
                    const rows = data.rows

                    if (!rows.length) {
                        this.$emit('state', 'failed')
                        return
                    }

                    const notSuggestedCompetitors = rows.filter(competitor => {
                        return competitor.suggested === false
                    })

                    if (notSuggestedCompetitors.length) {
                        this.competitor = notSuggestedCompetitors[Math.round(Math.random() * notSuggestedCompetitors.length-1)]
                        this.loadMetric('organicTraffic')
                        this.loadMetric('organicKeywords')
                        return
                    }

                    const suggestedCompetitors = rows.filter(competitor => {
                        return competitor.suggested === true
                    })

                    if (suggestedCompetitors.length) {
                        this.competitor = suggestedCompetitors[Math.round(Math.random() * suggestedCompetitors.length-1)]
                        this.loadMetric('organicTraffic')
                        this.loadMetric('organicKeywords')
                        return
                    }

                    this.$emit('state', 'failed')
                }, error => {
                    this.$emit('state', 'failed')
                })
            },

            loadMetric(metric) {
                CompetitorMetricService.findAll({
                    limit: 1,
                    sort: [
                        {
                            key: 'created',
                            direction: 'DESC'
                        }
                    ],
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'name',
                                    operator: 'eq',
                                    value: metric
                                },
                                {
                                    key: 'competitor',
                                    operator: 'eq',
                                    value: this.competitor.id
                                }
                            ]
                        }
                    ]
                }, response => {
                    const data = response.data
                    const rows = data.rows

                    if (!rows.length) {
                        this.$emit('state', 'failed')
                        return
                    }

                    this.$set(this.metrics, metric, rows[0].value)

                    this.$emit('state', 'success')
                }, error => {
                    this.$emit('state', 'failed')
                })
            }
        },

        components: {
            QuickviewRange
        }
    }
</script>
