<template>
    <div class="quickview-analytics-goals range-container" v-if="goal">
        <div class="row">
            <div class="col-lg-12 text-center goal-container">
                <h5 class="transition">{{goal.insights.total}}</h5>

                <p>
                    <small>
                        {{$t('overview.quickview.analyticsGoals.headline')}}<br/>
                        <span class="text-muted">{{goal.name}}</span>
                    </small>
                </p>
            </div>
        </div>
        <div class="row bottom-row">
            <div class="col-lg-12">
                <quickview-range :from="from" :to="to" :cta="cta"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .goal-container {
        padding-top: 12px;
    }

    .quickview-analytics-goals {
        .row {
            margin: 0;

            div:not(.loader) {
                height: 102px;
            }
        }
    }

    .range-container {
        position: relative;
        min-height: 159px;

        .bottom-row {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 15px;
            height: 41px;
        }
    }

    h5 {
        font-size: 30px;
        margin-bottom: 5px;
        font-weight: 500;
    }

    p {
        line-height: 16px;
    }
</style>

<script>
  import QuickviewRange from '@/app/layout/components/QuickviewRange'

  import GoalService from '@/services/_app/google-analytics/GoalService'

  import hasIntegration from '@/mixins/integrations/hasIntegration'

  const moment = require('moment')

  export default {
    mixins: [hasIntegration],

    data () {
      return {
        goal: null,
        goals: [],
        conversions: 0,
        from: moment().subtract(7, 'days'),
        to: moment().startOf('day').add(1, 'day'),
        cta: {
          label: $t('overview.quickview.analyticsGoals.cta'),
          link: {
            name: 'frontend.reporting.conversions'
          }
        }
      }
    },

    mounted () {
      if (!this.hasHealthyIntegration('Google analytics')) {
        this.$emit('state', 'failed')
        return
      }

      this.$emit('state', 'loading')

      this.load()
    },

    methods: {
      async loadInsights () {
        if (!this.goals.length) {
          this.$emit('state', 'failed')
          return
        }

        const goal = this.goals[0]

        try {
          const insights = await GoalService.insights(goal.id, this.from, this.to)

          if (!insights.rows) {
            this.$emit('state', 'failed')
            return
          }

          if (!insights.total > 0) {
            this.goals.shift()
            await this.loadInsights()
            return
          }

          goal.insights = insights
          this.goal = goal

          this.$emit('state', 'success')
        } catch (e) {
          this.$emit('state', 'failed')
        }
      },

      async load () {
        this.goals = []

        try {
          const data = await GoalService.findAll(this.from, this.to)

          if (!data.length) {
            this.$emit('state', 'failed')
            return
          }

          const activeGoals = data.filter(goal => goal.active === true)
          this.goals = activeGoals.map(a => [Math.random(), a])
            .sort((a, b) => a[0] - b[0])
            .map((a) => a[1])

          await this.loadInsights()
        } catch (e) {
          this.$emit('state', 'failed')
        }
      }
    },

    components: {
      QuickviewRange
    }
  }
</script>
