const BaseService = require('@/services/BaseService')

const namespace = 'google-business-profile/mybusiness/v4'

const find = (data, cb, errorCb) => {
    const url = `${namespace}/${data.name}`
    BaseService.get(url, {}, cb, errorCb)
}

const findAll = (data, cb, errorCb) => {
    const url = `${namespace}/${data.locationName}/localPosts`
    BaseService.get(url, {}, cb, errorCb)
}

const create = (data, cb, errorCb) => {
    const url = `${namespace}/${data.locationName}/localPosts`
    BaseService.post(url, {}, data.post, cb, errorCb)
}

const update = (payload, cb, errorCb) => {
    const url = `${namespace}/${payload.name}?updateMask=${payload.updateMask}`
    BaseService.patch(url, payload.post, cb, errorCb)
}

const remove = (data, cb, errorCb) => {
    BaseService.remove(`${namespace}/${data.name}`, {}, cb, errorCb)
}

const reportInsights = (options, cb, errorCb) => {
    const locationName = options.locationName

    const payload = {
        localPostNames: options.localPostNames,
        basicRequest: options.basicRequest
    }

    const url = `${namespace}/${locationName}/localPosts:reportInsights`
    BaseService.post(url, payload, cb, errorCb)
}

export {
    find,
    findAll,
    create,
    update,
    remove,
    reportInsights
}
