<template>
    <div>
        <div class="content" :class="{'no-icon': !activity.icon}">
            <span v-html="activity.headline"></span>

            <br>

            <span class="description">{{$t('newsFeed.website.noSsl.text')}}</span>

            <br><br>

            <span class="description">{{$t('newsFeed.website.noSsl.subtext')}}</span>
        </div>

        <div class="clearfix"></div>
    </div>
</template>

<style lang="scss" scoped>
    $imageSize: 25px;

    .activity {
        display: block;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        margin-top: 15px;
        margin-bottom: 15px;

        .content {
            float: left;
            font-size: 14px;
            width: calc(100% - #{$imageSize});

            &.no-icon {
                width: 100%;
                padding: 0;
            }
        }

        .description {
            color: #888;
        }

        .cta {
            margin-top: 8px;

            .btn {
                font-size: 12px;
                padding: 6px 13px;
                font-weight: 600;
            }
        }

        .preview {
            overflow: hidden;
            margin-top: 10px;

            padding: 5px;
            border-radius: 3px;

            img {
                max-width: 100%;
            }
        }
    }

    #reportModal .modal-content {
        overflow: hidden;
        border-radius: 8px;

        .close {
            margin-right: 7px;
        }
    }
</style>

<script>
    const FeedService = require('@/services/feed/FeedService')
    const HistoryService = require('@/services/reports/HistoryService')

    export default {
        props: {
            activity: Object
        },

        data() {
            return {
                expanded: false,
            }
        },

        computed: {
            subActivities() {
                let activities = this.activity.activities

                if (!activities || !activities.length) {
                    return []
                }

                activities.splice(0, 1)

                return activities.map((activity) => {
                    return FeedService.renderSub(activity)
                });
            },

            modalId() {
                return 'modal-' + this.activity.id
            }
        },

        methods: {
            expand() {
                this.expanded = true;
            },
        }
    }
</script>
