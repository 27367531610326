const BaseService = require('@/services/BaseService')
const namespace = 'access-google-ppc/integration'

/** OLD API */
//adInsights is not being used yet.
const adInsights = (from, to, integrationId, adGroupId, cb, errorCb) => {
	const route = `${namespace}/${integrationId}/query/`
	const query = 'SELECT ad_group_ad.ad.image_ad.image_url, ad_group_ad.ad.image_ad.preview_image_url, ad_group_ad.ad.final_urls, ad_group_ad.ad.display_url, segments.ad_network_type, ad_group.name, ad_group.type, ad_group_ad.ad.app_ad.html5_media_bundles FROM ad_group_ad WHERE ad_group_ad.ad.id = ' + adGroupId + ' AND segments.date BETWEEN "' + from.format('YYYY-MM-DD') + '" AND "' + to.format('YYYY-MM-DD') + '"'
	const options = { query: query }

	BaseService.get(route, options, cb, errorCb)
}

const adInsightsResponsive = (from, to, integrationId, adGroupId, cb, errorCb) => {
	const route = `${namespace}/${integrationId}/query/`
	const query = 'SELECT  ad_group.id,\n' +
		'  ad_group_ad.ad.id,\n' +
		'  ad_group_ad.ad.responsive_display_ad.business_name,\n' +
		'  ad_group_ad.ad.responsive_display_ad.descriptions,\n' +
		'  ad_group_ad.ad.responsive_display_ad.headlines,\n' +
		'  ad_group_ad.ad.responsive_display_ad.long_headline,\n' +
		'  ad_group_ad.ad.responsive_display_ad.logo_images,\n' +
		'  ad_group_ad.ad.responsive_display_ad.marketing_images,\n' +
		'  ad_group_ad.ad.responsive_display_ad.square_logo_images,\n' +
		'  ad_group_ad.ad.responsive_display_ad.square_marketing_images,\n' +
		'  ad_group_ad.status FROM ad_group_ad WHERE ad_group_ad.ad.id = ' + adGroupId + ' AND segments.date BETWEEN "' + from.format('YYYY-MM-DD') + '" AND "' + to.format('YYYY-MM-DD') + '"'
	const options = { query: query }

	BaseService.get(route, options, cb, errorCb)
}
const fetchAsset = (integrationId, customerId, assetId, cb, errorCb) => {
	const route = `${namespace}/${integrationId}/query/`
	const query = 'SELECT asset.image_asset.full_size.url FROM asset WHERE asset.id = "' + assetId + '" AND customer.id = "' + customerId + '"'
	const options = { query: query }

	BaseService.get(route, options, cb, errorCb)
}

/** NEW API BEGIN */
const metrics = [
	'metrics.all_conversions',
	'metrics.average_cpc',
	'metrics.average_cpv',
	'metrics.average_cpm',
	'metrics.average_cart_size',
	'metrics.clicks',
	'metrics.conversions',
	'metrics.conversions_value',
	'metrics.cost_micros',
	'metrics.cost_per_conversion',
	'metrics.ctr',
	'metrics.orders',
	'metrics.impressions',
	'metrics.interactions',
	'metrics.search_impression_share',
	'metrics.video_views',
	'metrics.video_view_rate',
	'metrics.view_through_conversions',
	'customer.currency_code',
]

const accountOverviewPerformance = (from, to, integrationId, cb, errorCb) => {
	const route = `${namespace}/${integrationId}/query/`

	const query = `
		SELECT ${metrics.join(', ')}
		FROM customer
		WHERE segments.date BETWEEN "${from.format('YYYY-MM-DD')}" AND "${to.format('YYYY-MM-DD')}"
	`

	const options = { query: query }

	BaseService.get(route, options, cb, errorCb)
}

const accountNetworkPerformance = (from, to, integrationId, cb, errorCb, networks = []) => {
	const route = `${namespace}/${integrationId}/query/`

	let metricsCopy = [...metrics]

	if (!! networks && networks.length > 0) {
		metricsCopy.push('segments.ad_network_type')
	}

	let query = `
		SELECT ${metricsCopy.join(', ')}
		FROM customer
		WHERE segments.date BETWEEN "${from.format('YYYY-MM-DD')}" AND "${to.format('YYYY-MM-DD')}"
	`

	if (!! networks && networks.length > 0) {
		query += ` AND segments.ad_network_type IN ("${networks.join('", "')}")`
	}

	BaseService.get(route, { query: query }, cb, errorCb)
}

const accountNetworkTypes = (from, to, integrationId, cb, errorCb) => {
	const route = `${namespace}/${integrationId}/query/`

	const query = `
		SELECT ${[...metrics, 'segments.ad_network_type'].join(', ')}
		FROM customer
		WHERE segments.date BETWEEN "${from.format('YYYY-MM-DD')}" AND "${to.format('YYYY-MM-DD')}"
	`
	BaseService.get(route, { query: query }, cb, errorCb)
}

const fetchCampaigns = (
	from,
	to,
	integrationId,
	cb,
	errorCb,
	statuses = ['ENABLED', 'PAUSED'],
	limit = null,
	page = 1,
	networks = null,
	orderBy = null
) => {
	const route = `${namespace}/${integrationId}/query/`

	const metricsCopy = [...metrics]

	if (!! networks && networks.length > 0) {
		metricsCopy.push('segments.ad_network_type')
	}

	let query = `
		SELECT ${[
			...metricsCopy,
			'campaign.name',
			'campaign.status',
			'campaign.id',
			'campaign.start_date',
			'campaign.end_date',
		].join(', ')}
		FROM campaign
		WHERE campaign.status IN ("${statuses.join('", "')}") AND segments.date BETWEEN "${from.format('YYYY-MM-DD')}" AND "${to.format('YYYY-MM-DD')}"`

	if (!! networks && networks.length > 0) {
		query += ` AND segments.ad_network_type IN ("${networks.join(', ')}") `
	}

	if (!! orderBy) {
		query += ` ORDER BY ${orderBy} `
	}

	if (limit) {
		query += ` LIMIT ${limit}`
	}

	BaseService.get(route, { query: query }, cb, errorCb)
}

const fetchCampaignsConversions = (
	from,
	to,
	integrationId,
	cb,
	errorCb,
	statuses = ['ENABLED', 'PAUSED'],
	limit = null,
	page = 1,
	networks = null
) => {
	const route = `${namespace}/${integrationId}/query/`

	const metricsCopy = [...metrics]

	if (!! networks && networks.length > 0) {
		metricsCopy.push('segments.ad_network_type')
	}

	let query = `
		SELECT ${[
			'campaign.id',
			'segments.conversion_action',
			'segments.conversion_action_name',
			'metrics.conversions',
			'metrics.conversions_value'
		].join(', ')}
		FROM campaign
		WHERE campaign.status IN ("${statuses.join('", "')}") AND segments.date BETWEEN "${from.format('YYYY-MM-DD')}" AND "${to.format('YYYY-MM-DD')}"`

	if (!! networks && networks.length > 0) {
		query += ` AND segments.ad_network_type IN ("${networks.join(', ')}") `
	}

	if (limit) {
		query += ` LIMIT ${limit}`
	}

	BaseService.get(route, { query: query }, cb, errorCb)
}

const fetchCampaignSearchTerm = (from, to, campaignId, integrationId, cb, errorCb) => {
	const route = `${namespace}/${integrationId}/query/`
	let query = 'SELECT ad_group_criterion.keyword.text, ad_group.name FROM ad_group_criterion WHERE campaign.status IN ("ENABLED", "PAUSED") AND ad_group_criterion.status IN ("ENABLED", "PAUSED")';
	query += ' AND campaign.id = "' + campaignId + '"';
	query += ' AND ad_group.status != "' + 'REMOVED' + '"';
	query += ' AND ad_group_criterion.negative = "' + false + '"';

	const options = { query: query }

	BaseService.get(route, options, cb, errorCb)
}

const fetchGroups = (
	from,
	to,
	integrationId,
	campaignId,
	cb,
	errorCb,
	limit = null,
	page = 1,
	networks = null,
	all = false,
	statuses = []
) => {
	const route = `${namespace}/${integrationId}/query/`

	const metricsCopy = [...metrics]

	if (!! networks && networks.length > 0) {
		metricsCopy.push('segments.ad_network_type')
	}

	if (! all) {
		metricsCopy.push('segments.device')
	}

	let query = `
		SELECT ${[
			...metricsCopy,

			'ad_group.name',
			'ad_group.id',
			'ad_group.status',
			'campaign.name',
		].join(', ')}
		FROM ad_group
		WHERE
			campaign.id = ${campaignId} AND
			segments.date BETWEEN "${from.format('YYYY-MM-DD')}" AND "${to.format('YYYY-MM-DD')}"`

	if (!! networks && networks.length > 0) {
		query += ` AND segments.ad_network_type IN ("${networks.join('", "')}")`
	}

	if (statuses.length > 0) {
		query += ` AND ad_group.status IN ("${statuses.join('", "')}")`
	}

	if (limit) {
		query += ` LIMIT ${limit}`
	}

	const options = { query: query }

	BaseService.get(route, options, cb, errorCb)
}

const fetchGroupsConversions = (
	from,
	to,
	integrationId,
	campaignId,
	cb,
	errorCb,
	limit = null,
	networks = null,
	statuses = []
) => {
	const route = `${namespace}/${integrationId}/query/`

	const metricsCopy = [...metrics]

	if (!! networks && networks.length > 0) {
		metricsCopy.push('segments.ad_network_type')
	}

	let query = `
		SELECT ${[
			'campaign.id',
			'ad_group.id',
			'segments.conversion_action',
			'segments.conversion_action_name',
			'metrics.conversions',
			'metrics.conversions_value'
		].join(', ')}
		FROM ad_group
		WHERE
			campaign.id = ${campaignId} AND
			segments.date BETWEEN "${from.format('YYYY-MM-DD')}" AND "${to.format('YYYY-MM-DD')}"`

	if (!! networks && networks.length > 0) {
		query += ` AND segments.ad_network_type IN ("${networks.join('", "')}")`
	}

	if (statuses.length > 0) {
		query += ` AND ad_group.status IN ("${statuses.join('", "')}")`
	}

	const options = { query: query }

	BaseService.get(route, options, cb, errorCb)
}

const fetchAds = (
	from,
	to,
	integrationId,
	adGroupId,
	cb,
	errorCb,
	limit = null,
	page = 1,
	networks = null,
	all = false,
	statuses = [],
	orderBy = null
) => {
	const route = `${namespace}/${integrationId}/query/`

	const metricsCopy = [...metrics]

	if (!! networks && networks.length > 0) {
		metricsCopy.push('segments.ad_network_type')
	}

	if (! all) {
		metricsCopy.push('segments.device')
	}

	const except = ['metrics.search_impression_share']

	let query = `
		SELECT ${[
			...metricsCopy,
			'ad_group_ad.ad.expanded_text_ad.headline_part1',
			'ad_group_ad.ad.expanded_text_ad.headline_part2',
			'ad_group_ad.ad.expanded_text_ad.headline_part3',
			'ad_group_ad.ad.expanded_text_ad.description',
			'ad_group_ad.ad.expanded_text_ad.description2',
			'ad_group_ad.ad.expanded_text_ad.path1',
			'ad_group_ad.ad.expanded_text_ad.path2',
			'ad_group_ad.ad.type',
			'ad_group_ad.status',
			'ad_group_ad.ad.id',
			'ad_group.name'
		].filter(value => ! except.includes(value)).join(', ')}
		FROM ad_group_ad
		WHERE
			ad_group.id = ${adGroupId} AND
			segments.date BETWEEN "${from.format('YYYY-MM-DD')}" AND "${to.format('YYYY-MM-DD')}"`

	if (!! networks && networks.length > 0) {
		query += ` AND segments.ad_network_type IN ("${networks.join('", "')}")`
	}

	if (statuses.length > 0) {
		query += ` AND ad_group_ad.status IN ("${statuses.join('", "')}")`
	}

	if (!! orderBy) {
		query += ` ORDER BY ${orderBy} `
	}

	if (limit) {
		query += ` LIMIT ${limit}`
	}

	const options = { query: query }

	BaseService.get(route, options, cb, errorCb)
}

const fetchAds2 = (from, to, integrationId, adGroupId, cb, errorCb) => {
	const route = `${namespace}/${integrationId}/query/`
	const query = 'SELECT ad_group_ad.ad.expanded_text_ad.headline_part1, ad_group_ad.ad.expanded_text_ad.headline_part2, ad_group_ad.ad.expanded_text_ad.headline_part3, ad_group_ad.ad.expanded_text_ad.description, ad_group_ad.ad.expanded_text_ad.description2, ad_group_ad.ad.expanded_text_ad.path1, ad_group_ad.ad.expanded_text_ad.path2, ad_group_ad.ad.type, ad_group_ad.status, ad_group_ad.ad.id, metrics.conversions_value, metrics.conversions, metrics.clicks, metrics.average_cpv, metrics.cost_micros, metrics.impressions, metrics.interactions, metrics.video_view_rate, metrics.video_views, segments.ad_network_type, segments.device FROM ad_group_ad WHERE ad_group.id = ' + adGroupId + ' AND ad_group_ad.status = "ENABLED" AND segments.date BETWEEN "' + from.format('YYYY-MM-DD') + '" AND "' + to.format('YYYY-MM-DD') + '"'
	const options = { query: query }

	BaseService.get(route, options, cb, errorCb)
}

const fetchPlacements = (from, to, integrationId, cb, errorCb) => {
	const route = `${namespace}/${integrationId}/query/`
	const query =   'SELECT ad_group.name, ad_group.id, campaign.name, metrics.clicks, metrics.average_cpc, metrics.impressions, metrics.conversions, group_placement_view.display_name, group_placement_view.placement, group_placement_view.placement_type, segments.ad_network_type, customer.currency_code FROM group_placement_view WHERE segments.ad_network_type = "CONTENT" AND segments.date BETWEEN "' + from.format('YYYY-MM-DD') + '" AND "' + to.format('YYYY-MM-DD') + '"'
	const options = { query: query }

	BaseService.get(route, options, cb, errorCb)
}
/** NEW API END */

export {
	accountOverviewPerformance,
	accountNetworkTypes,
	fetchCampaigns,
	fetchCampaignsConversions,
	fetchCampaignSearchTerm,
	fetchGroups,
	fetchGroupsConversions,
	fetchAds,
	fetchPlacements,
	adInsights,
	adInsightsResponsive,
	fetchAsset,
	accountNetworkPerformance
}
