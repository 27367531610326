<template>
    <div>
        <hr v-if="isSubActivity">

        <img v-if="activity.details !== null" :src="activity.details.image" class="img-responsive">
    </div>
</template>

<script>
    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>
