<template>
    <div class="load-time">
        <div class="bar" :class="{'load-time-ideal-tracker': loadTimeIdeal}">
            <div class="pin" :style="{left: progress + '%'}">
                <span class="your-website" :class="{'left-end': progress === 0, 'right-end': progress === 100}">{{$t('newsFeed.website.loadTime.website')}}</span>

                <strong class="seconds">{{loadTime}}s</strong>

                <img src="/images/feed/website/arrow-load-time.png">
            </div>

            <div class="pin reversed" :style="{left: idealProgress + '%'}" v-if="loadTimeIdeal">
                <span class="your-website" :class="{'left-end': idealProgress === 0, 'right-end': idealProgress === 100}">{{$t('newsFeed.website.loadTime.recommended')}}</span>

                <strong class="seconds">{{loadTimeIdeal}}s</strong>

                <img src="/images/feed/website/arrow-load-time.png">
            </div>

            <div class="overlay" :style="{width: 100 - progress + '%'}"></div>

            <div v-for="(item, index) in blocks">
              <div class="category-container" :class="'category-' + (index + 1)" :style="{width: item.size + '%'}">
                  <span class="seconds">{{ item.second }}s</span>

                  <span class="category hidden-xs">{{ $t('newsFeed.website.loadTime.categories.' + item.text) }}</span>
              </div>
            </div>
        </div>

        <div class="row details">
            <div class="col-md-6">
                <span>{{$t('newsFeed.website.loadTime.loadingTimeWebsite')}}</span>

                <strong>{{loadTime}} {{$tc('newsFeed.website.loadTime.seconds', loadTime)}}</strong>
            </div>

            <div class="col-md-6">
                <span>{{$t('newsFeed.website.loadTime.category')}}</span>

                <strong class="success" v-if="loadTime <= 3">{{ $t('newsFeed.website.loadTime.categories.best') }}</strong>

                <strong class="success" v-else-if="loadTime <= 5">{{ $t('newsFeed.website.loadTime.categories.good') }}</strong>

                <strong class="warning" v-else-if="loadTime <= 8">{{ $t('newsFeed.website.loadTime.categories.ok') }}</strong>

                <strong class="danger" v-else>{{ $t('newsFeed.website.loadTime.categories.bad') }}</strong>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .bar {
        height: 85px;
        margin-bottom: 30px;
        margin-top: 115px;
        position: relative;

        &.load-time-ideal-tracker {
          margin-bottom:80px;
        }

        .pin {
            position: absolute;
            top: -57px;
            margin-left: -24px;
            z-index: 10;

            img {
                width: 50px;
            }

            .seconds {
                position: absolute;
                top: 17px;
                left: 50%;
                width: 30px;
                margin-left: -15px;
                text-align: center;
                font-size: 16px;
                z-index:99;
            }

            .your-website {
                position: absolute;
                top: -25px;
                left: 50%;
                width: 200px;
                margin-left: -100px;
                text-align: center;

                &.right-end {
                    right: 0;
                    left: auto;
                    text-align: right;
                }

                &.left-end {
                    left: 0;
                    text-align: left;
                }
            }


            &.reversed {
              top: auto;
              bottom: -63px;

              img {
                transform: rotate(180deg);
              }

              .seconds {
                top: 25px;
              }

              .your-website {
                top: auto;
                bottom: -25px;
              }
            }

        }

        .category-container {
            float: left;
            height: 100%;
            display: flex;
            flex-wrap: wrap;

            &.category-1 {
                background: $secondarySuccess;
            }

            &.category-2 {
                background: $primarySuccess;
            }

            &.category-3 {
                background: $primaryWarning;
            }

            &.category-4 {
                background: $secondaryAlert;
            }

            span {
                flex: 100%;
                text-align: right;
                padding: 14px 12px;
                color: #000;
                opacity: 0.35;
                font-weight: 500;
                font-size: 12px;
            }
        }
    }

    .details {
        span {
            display: block;
            margin-top: 15px;
        }

        strong {
            font-size: 16px;
        }

        .success {
            color: $tertiarySuccess;
        }

        .warning {
            color: $secondaryWarning;
        }

        .danger {
            color: $tertiaryAlert;
        }
    }
</style>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                blocks: [
                    // Second = display in seconds
                    // Size = percentage of width
                    {second: 3, size: 20, text: 'best'},
                    {second: 5, size: 30, text: 'good'},
                    {second: 8, size: 30, text: 'ok'},
                    {second: 10, size: 20, text: 'bad'}
                ],
            }
        },

        computed: {
            loadTimeObject() {
                // Fix for wrong object indention
                const details = this.activity.details
                const loadTime = details.load_time

                return loadTime.now !== undefined && typeof loadTime.now === 'object' ? loadTime.now : loadTime
            },

            loadTime() {
                const loadTime = this.loadTimeObject

                if (!loadTime || !loadTime.now) {
                    return null
                }

                return Math.round(this.loadTimeObject.now)
            },

            loadTimeIdeal() {
                const loadTime = this.loadTimeObject

                if (!loadTime || !loadTime.ideal) {
                    return null
                }

                return loadTime.ideal
            },

            idealProgress() {
                const loadTime = this.loadTimeObject

                if (!loadTime || !loadTime.ideal) {
                    return null
                }

                return this.findPercentageBySecond(loadTime.ideal)
            },

            progress() {
                const loadTime = this.loadTimeObject

                if (!loadTime || !loadTime.now) {
                    return null
                }

                return this.findPercentageBySecond(loadTime.now)
            }
        },

        methods: {
            closestValue(num){
                let i = 0,
                    minDiff = 1000,
                    ans = 0,
                    array = this.blocks

                for(i in array){
                    let m = Math.abs(num - array[i].second);

                    if (m <= minDiff){
                        minDiff = m;

                        ans = {index: parseInt(i, 10), array: array[i]};
                    }
                }

                return ans;
            },

            findPercentageBySecond(second) {
                if (second >= 10) {
                    return 100
                }

                let closest = this.closestValue(second),
                    percentage = this.blocks.slice(0, closest.index + 1)
                        .map((a) => { return a.size })
                        .reduce((a,b) => { return a + b }, 0)

                if (closest.array.second == second) {
                    return percentage
                }

                let previousValue = closest.index === 0 ? 0 : this.blocks[closest.index - 1].second,
                    distribution = closest.array.size / (closest.array.second - previousValue),
                    difference = distribution * (closest.array.second - second)

                return percentage - difference
            },
        }
    }
</script>
