<template>
    <div class="benchmark-chart center-block">
        <div class="user">
            <div>
                <div class="align-to-bottom pull-right">
                    <strong class="pull-right">{{ $t('newsFeed.website.sessionDuration.you') }}</strong>
                    <img src="/images/feed/benchmarks/person.svg" class="pull-right">
                </div>

                <div class="clearfix"></div>

                <div class="bar pull-right text-right" :style="{'height': userBarHeight + 'px'}">
                    <span>{{value}} {{unit}}</span>
                </div>
            </div>
        </div>

        <div class="benchmark">
            <div>
                <div class="align-to-bottom">
                    <img src="/images/feed/benchmarks/group.svg" class="pull-left">
                    <strong class="pull-left">{{ $t('newsFeed.website.sessionDuration.industry') }}</strong>
                </div>

                <div class="clearfix"></div>

                <div class="bar" :style="{'height': benchmarBarkHeight + 'px'}">
                    <span>{{benchmark}} {{unit}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .align-to-bottom {
        display: flex;
        align-items: flex-end;
    }

    .benchmark-chart {
        width: 340px;
        display: flex;
        align-items: flex-end;

        & > div {
            padding: 10px;
            width: 50%;
        }
    }

    strong {
        font-size: 16px;
        margin-bottom: 5px;
        line-height: 20px;
    }

    img {
        width: 38px;
        margin-bottom: 5px;
        margin-top: auto;
    }

    .bar {
        padding: 10px;
        color: white;
        font-size: 20px;
        font-weight: lighter;
        width: 100%;
    }

    .user {
        .bar {
            background: #01c9a3;
        }
    }

    .benchmark {
        strong {
            margin-left: 10px;
        }

        .bar {
            background: #2557b2;
        }
    }

    @media (max-width: 768px) {
        .benchmark-chart {
            width: 273px !important;
        }
    }
</style>

<script>
    export default {
        props: {
            value: [String, Number],
            benchmark: [String, Number],
            unit: String,
        },

        data() {
            return {
                minimumHeight: 75,
            }
        },

        computed: {
            userBarHeight() {
                if (this.value < this.benchmark) {
                    return this.minimumHeight
                }

                if (this.benchmark === 0) {
                    return this.minimumHeight
                }

                const height = (this.value / this.benchmark) * this.minimumHeight

                if (height > this.minimumHeight * 3) {
                    return this.minimumHeight * 3
                }

                return height
            },

            benchmarBarkHeight() {
                if (this.benchmark < this.value) {
                    return this.minimumHeight
                }

                if (this.value === 0) {
                    return this.minimumHeight
                }

                const height = (this.benchmark / this.value) * this.minimumHeight

                if (height > this.minimumHeight * 3) {
                    return this.minimumHeight * 3
                }

                return height
            }
        }
    }
</script>
