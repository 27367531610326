<template>
    <div class="onboarding-integrations">
        <hr>

        <integration name="Google analytics" service="analytics" logo="/images/onboarding/google-analytics.svg" :headline="$t('overview.onboarding.analytics.headline')" :description="$t('overview.onboarding.analytics.description')"></integration>

        <hr>

        <integration name="Google Ads" service="google-ads" logo="/images/onboarding/google-adwords.svg" :headline="$t('overview.onboarding.adwords.headline')" :description="$t('overview.onboarding.adwords.description')"></integration>

        <hr>

        <integration name="Facebook ads" service="facebook-ads" logo="/images/onboarding/facebook-ads.svg" :headline="$t('overview.onboarding.facebookAds.headline')" :description="$t('overview.onboarding.facebookAds.description')"></integration>

        <hr>

        <hr>

        <div class="see-more text-center">
            <router-link :to="{name: 'frontend.settings.integrations'}" class="text-muted">{{$t('overview.onboarding.button')}}</router-link>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .onboarding-integrations {
        hr {
            margin: 0 -30px;
        }

        .integration {
            margin: 0 -30px;
        }

        .integration-container {
            position: relative;
            background-color: #fff;

            > div {
                padding: 28px 0;
            }

            img {
                width: 30px;
                margin-right: 15px;
                margin-top: 3px;
            }

            h5 {
                font-size: 18px;
                padding: 0;
                margin: 0;
                line-height: 1.2;
            }

            .text-container {
                width: calc(100% - 50px);
            }

            .connected {
                padding-top: 20px;
                font-weight: 500;

                .fa-check {
                    color: green;
                }
            }

            .m-top {
                margin-top: 10px;
            }

            .btn {
                margin-top: 15px;

                &.btn-adwords {
                    background: #49a16a;
                }

                &.btn-facebook {
                    background: #405a94;
                }

                &.btn-calls {
                    background: #76c049;
                }

                &.btn-forms {
                    background: #167cd8;
                }

                &.btn-dinero {
                    background: #398db9;
                }

                &.btn-google-places {
                    background: #4780e8;
                }
            }

            .logo {
                width: 100%;
                height: 40px;
                padding-top: 5px;

                > img {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 100%;
                }
            }
        }

        .see-more {
            padding-top: 25px;

            a {
                color: #888;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
</style>

<script>
import Integration from '@/app/onboarding/components/integrations/Integration'
import hasIntegration from '@/mixins/integrations/hasIntegration'

export default {
    mixins: [hasIntegration],

      data() {
        return {
          a: false
        }
      },

      components: {
        Integration
      }
    }
</script>
