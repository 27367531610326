<template>
    <div class="quickview-inquiries range-container">
        <div class="row" style="margin: 0;">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center value-container" style="height: 102px;">
                <i class="pe-7s-phone call" :style="organizationCallsColor" />
                <h5 class="transition">{{calls | numberFormat}}</h5>
                <p><small>{{$t('overview.quickview.inquiries.calls')}}</small></p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center value-container" style="height: 102px;">
                <i class="pe-7s-mail email" :style="organizationEmailsColor" />
                <h5 class="transition">{{emails | numberFormat}}</h5>
                <p><small>{{$t('overview.quickview.inquiries.emails')}}</small></p>
            </div>
        </div>
        <div class="row bottom-row">
                <quickview-range :from="from" :to="to" :cta="cta" />
        </div>
    </div>
</template>


<style lang="scss" scoped>
    $call: #00438f;
    $email: #00c49f;

    .call {
        color: $call;
    }

    .email {
        color: $email;
    }

    .range-container {
        padding-bottom: 41px;
        position: relative;
        min-height: 159px;
        .value-container {
            padding-top: 10px;
        }
        .bottom-row {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 41px;
        }
    }

    i {
        font-size: 20px;
    }

    h5 {
        font-size: 30px;
        margin-bottom: 5px;
        font-weight: 500;
    }

    p {
        font-size: 14px;
        line-height: 16px;
    }
</style>

<script>
    import QuickviewRange from '@/app/layout/components/QuickviewRange'

    import hasIntegration from '@/mixins/integrations/hasIntegration'
	import {mapGetters} from "vuex";

    const CallService = require('@/services/inquiries/CallService')
    const FormService = require('@/services/inquiries/FormService')

    const moment = require('moment')

    export default {
        mixins: [hasIntegration],

        data() {
            return {
                from: moment().subtract(30, 'days'),
                to: moment(),
                servicesLoaded: 0,
                calls: 0,
                emails: 0,
                cta: {
                    label: $t('overview.quickview.inquiries.cta'),
                    link: {
                        name: 'frontend.inquiries'
                    }
                }
            }
        },

        computed: {
			...mapGetters('customer', {
				customer: 'getCustomer'
			}),

            organizationCallsColor() {
                return {color:$org('colors.inquiries.calls')}
            },

            organizationEmailsColor() {
                return {color:$org('colors.inquiries.emails')}
            }
        },

        watch: {
            servicesLoaded() {
                if (this.servicesLoaded === 2) {
                    this.$emit('state', 'success')
                }
            }
        },

        mounted() {
            this.$emit('state', 'loading')

            if (!this.hasHealthyIntegration(['Freespee', 'Formstack', 'WordPress forms'])) {
                this.$emit('state', 'failed')
                return
            }

            this.load()
        },

        methods: {
            load() {
                const to = moment(this.to)
                to.add(1, 'day')

                const options = {
                    limit: 1,
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'date',
                                    value: this.from.format('YYYY-MM-DD'),
                                    operator: 'gt',
                                },
                                {
                                    key: 'date',
                                    value: to.format('YYYY-MM-DD'),
                                    operator: 'lt',
                                }
                            ]
                        },
						{
						filters: [{
								key: 'customer',
								operator: 'eq',
								value: this.customer.id
							}]
						}
					]
                }

                CallService.findAll(options, response => {
                    const body = response.data

                    this.calls = body.total
                    this.servicesLoaded++
                }, () => {
                    this.$emit('state', 'failed')
                })

                FormService.findAll(options, response => {
                    const body = response.data

                    this.emails = body.total
                    this.servicesLoaded++
                }, () => {
                    this.$emit('state', 'failed')
                })
            }
        },

        components: {
            QuickviewRange
        }
    }
</script>
