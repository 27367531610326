<template>
    <div class="asideview-facebook-ads" v-if="hasLoaded">
        <div class="row">
            <div class="col-lg-12" style="margin-bottom: 20px;">
                <i class="fa fa-facebook" style="display: inline; float: left; margin-right: 10px;"/>
                <h6>{{$t('overview.quickview.facebookAds.headline')}}</h6>
                <p>{{campaign.name}}</p>
            </div>

            <div class="col-lg-3 text-center">
                <strong>{{$t('overview.quickview.facebookAds.reach')}}</strong><br/>
                {{campaign.insights.reach}}
            </div>

            <div class="col-lg-3 text-center">
                <strong>{{$t('overview.quickview.facebookAds.clicks')}}</strong><br/>
                {{campaign.insights.clicks}}
            </div>

            <div class="col-lg-3 text-center">
                <strong>{{$t('overview.quickview.facebookAds.spend')}}</strong>
                <br/>
                <span v-html="campaign.insights.spend"/> {{$t('overview.quickview.adWords.affix')}}
            </div>

            <div class="col-lg-3 text-center">
                <strong>{{$t('overview.quickview.facebookAds.ctr')}}</strong><br/>
                {{campaign.insights.ctr | fixedNumber(1)}}%
            </div>
        </div>

        <div class="row bottom-row">
            <div class="col-lg-12">
                <quickview-range :from="from" :to="to" :cta="cta"/>
            </div>
        </div>
    </div>
</template>

<style>
    .asideview-facebook-ads .accountCurrency {
        display: none;
    }
</style>

<style lang="scss" scoped>
    .data-container {
        background: #f9f9f9;
        padding: 10px 10px 5px 10px;
        border-radius: 3px;
        margin: 0 5px;
    }

</style>

<script>
  import QuickviewRange from '@/app/layout/components/QuickviewRange'
  import hasIntegration from '@/mixins/integrations/hasIntegration'
  import moment from 'moment'

  export default {
    mixins: [hasIntegration],

    data () {
      return {
        from: moment().startOf('day'),
        to: moment().startOf('day').add(1, 'day'),
        fields: ['clicks', 'reach', 'spend', 'ctr'],
        campaign: null,
        cta: {
          label: $t('overview.quickview.facebookAds.cta'),
          link: {
            name: 'frontend.reporting.facebook.ads'
          }
        }
      }
    },

    computed: {
      hasLoaded () {
        const campaign = this.campaign
        return campaign && Object.keys(campaign.insights).length
      }
    },

    mounted () {
      this.$emit('state', 'loading')

      if (!this.hasHealthyIntegration('Facebook ads')) {
        this.$emit('state', 'failed')
        return
      }

	  eventHub.$on('topbar.filterGroups.sources.metricsChanged', this.load)
      this.load()
    },

    methods: {
      async loadCampaign () {
        const { campaigns } = await this.$store.dispatch('facebookAds/loadCampaigns', { status: 'ACTIVE', limit: 3 })

        let campaign = null

        for (let i = 0; i < campaigns.length; i++) {
          if (campaigns[i].status === 'ACTIVE') {
            campaign = campaigns[i]
            break
          }
        }

        if (!campaign) {
          this.$emit('state', 'failed')
          return
        }

        return await this.loadInsights(campaign)
      },

      async loadInsights (campaign){
        const from = this.from.format('YYYY-MM-DD')
        const to = this.to.format('YYYY-MM-DD')

		const response = await this.$store.dispatch('facebookAds/loadCampaignInsights', { campaign, from, to })
        const insights = response.insights

        if (!Object.keys(insights).length) {
          this.$emit('state', 'failed')
          return
        }

        const fields = {
          clicks: insights.outbound_clicks,
          reach: insights.reach,
          spend: insights.spend,
          ctr: insights.outbound_clicks_ctr
        }

        return  {...campaign, ...response.campaign, insights: fields}
      },

      async load () {

        try {
          let campaign = await this.loadCampaign()

          this.$emit('state', 'success')

          this.campaign = campaign
        } catch (e) {
          this.$emit('state', 'failed')
        }
      }
    },

    components: {
      QuickviewRange
    }
  }
</script>
