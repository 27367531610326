<template>
    <div>
        <div class="content" :class="{'no-icon': !activity.icon}">
            <span v-html="activity.headline"></span>

            <br>

            <span class="description" v-html="activity.description"></span>

            <div class="preview" v-if="activity.image">
                <img :src="activity.image">
            </div>

            <div class="cta" target="_blank" rel="noopener noreferrer" v-if="activity.cta.length > 0" v-for="button in activity.cta">
                <a v-if="activity.verb == 'report_created'" @click="openReport(button.historyId)" class="hidden-xs hidden-sm" :class="button.class">
                    {{button.title}}
                    <i v-if="loadingReport" class="fa fa-circle-o-notch fa-spin"></i>
                </a>
                <a v-else :path="button.path" :class="button.class">{{button.title}}</a>
            </div>
        </div>

        <div class="clearfix"></div>

        <!-- Modal -->
        <div :id="modalId" class="modal fade" role="dialog" v-if="activity.verb == 'report_created'">
            <div class="modal-dialog modal-lg">

                <!-- Modal content-->
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>

                    <div v-html="reportHtml"></div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">{{$t('newsFeed.report.reportCreated.close')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    $imageSize: 25px;

    .activity {
        display: block;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        margin-top: 15px;
        margin-bottom: 15px;

        .content {
            float: left;
            font-size: 14px;
            width: calc(100% - #{$imageSize});

            &.no-icon {
                width: 100%;
                padding: 0;
            }
        }

        .description {
            color: #888;
        }

        .cta {
            margin-top: 8px;

            .btn {
                font-size: 12px;
                padding: 6px 13px;
                font-weight: 600;
            }
        }

        .preview {
            overflow: hidden;
            margin-top: 10px;

            padding: 5px;
            border-radius: 3px;

            img {
                max-width: 100%;
            }
        }
    }

    #reportModal .modal-content {
        overflow: hidden;
        border-radius: 8px;

        .close {
            margin-right: 7px;
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import Activity from '@/app/news-feed/components/feed/Activity'

    const FeedService = require('@/services/feed/FeedService')
    const HistoryService = require('@/services/reports/HistoryService')

    export default {
        props: {
            activity: Object
        },

        data() {
            return {
                expanded: false,
                loadingReport: false,
                reportHtml: '',
            }
        },

        computed: {
            subActivities() {
                let activities = this.activity.activities;

                if (!activities || !activities.length) {
                    return []
                }

                activities.splice(0, 1)

                return activities.map((activity) => {
                    return FeedService.renderSub(activity)
                });
            },

            modalId() {
                return 'modal-' + this.activity.id
            }
        },

        methods: {
            expand() {
                this.expanded = true;
            },

            openReport(historyId) {
                this.loadingReport = true

                HistoryService.find(historyId, null, (response) => {
                    const body = response.data

                    this.reportHtml = body.html

                    jQuery('#' + this.modalId).modal('show');

                    this.loadingReport = false
                })
            },
        },

        components: {
            Card
        }
    }
</script>
