<template>
    <div class="container-fluid">
        <div v-if="details" v-for="(metric, index) in metrics" class="row">
            <div v-for="value in metric" class="col-lg-4 col-md-4 col-sm-4 col-xs-12 content-container" style="text-align: center;">
                <i :class="iconForKey(value.icon)"></i>

                <span class="body__value">{{details[value.metric]}}</span>

                <span class="body__details">{{translationForMetric(value.metric)}}</span>
            </div>

            <div v-if="index < metrics.length - 1" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 divider"></div>
        </div>

        <div class="row cta-container">
            <div class="col-lg-12">
                <router-link :to="{name: 'frontend.reporting.facebook.ads'}">
                    <span>{{this.translationForKey('cta', {})}}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .container-fluid {
      padding:35px 25px;
    }
    .body__icon {
        font-size: 32px;
        color: $focusPositive;
        margin-bottom: 10px;
    }
    .body__value {
        font-size: 24px;
        font-weight: 300;
    }
    .body__details {
        font-size: 12px;
        color: #888888;
    }
    span {
        display: block;
    }
    .divider {
        border-bottom: 1px solid #eaeaea;
        margin: 40px 0;
    }
    .cta-container {
        div {
          text-align: center;
          span {
            padding: 8px 16px;
            border-radius: 3px;
            text-align: center;
            margin: 40px 0 0 0;
            color: #FFA534;
            border: 1px solid #FFA534;
            cursor: default;
            display: inline-block;
          }
        }
      }

   @media screen and (max-width: 768px) {
      .content-container {
        margin-bottom: 40px;
      }
      .divider {
        margin: 0 0 40px 0;
      }
    }

</style>

<script>
    export default {
        props: {
            activity: Object,

            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                metrics: [
                    [
                        { icon: 'mouse', metric: 'clicks' },
                        { icon: 'graph3', metric: 'ctr' },
                        { icon: 'piggy', metric: 'avgCpc' }
                    ],
                    [
                        { icon: 'look', metric: 'impressions' },
                        { icon: 'graph3', metric: 'interactionRate' },
                        { icon: 'mouse', metric: 'interactions' }
                    ]
                ]
            }
        },

        computed: {
            details() {
                const details = this.activity.details ? JSON.parse(JSON.stringify(this.activity.details)) : {}

                if (!Object.values(details).length) {
                    return null
                }


                details.ctr = this.percentageFormat(details.ctr)
                details.interactionRate = this.percentageFormat(details.interactionRate)
                details.avgCpc = this.translationForKey('currency', {value: this.numberFormat(details.avgCpc)})

                details.clicks = this.numberFormat(details.clicks)
                details.impressions = this.numberFormat(details.impressions)
                details.interactions = this.numberFormat(details.interactions)

                return details
            }
        },

        methods: {
            numberFormat(number) {
                return this.$options.filters.numberFormat(number)
            },

            percentageFormat(number) {
                return number.toFixed(2) + '%'
            },

            translationForMetric(metric) {
                return this.translationForKey(`metrics.${metric}`, {})
            },

            translationForKey(key, parameters) {
                return $t(`newsFeed.facebook.summary.${key}`, parameters)
            },

            iconForKey(key) {
                return `body__icon pe-7s-${key}`
            }
        }
    }
</script>
