const BaseService = require('@/services/BaseService')

const namespace = 'stream/priority/hide'

const hide = (options, cb, errorCb) => {
  BaseService.post(namespace, options, cb, errorCb)
}

export {
  hide,
}
