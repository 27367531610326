<template>
    <div class="newsfeed-item">
        <div class="newsfeed-item__body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 wp-image" style="background-image: url('images/feed/website/wordpress-threat.svg');"></div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 wp-text">
                        <p v-html="$t('newsFeed.website.vulnerabilityDetected.body', {
                          version: vulnerability['version'],
                          vulnerabilities: vulnerability['vulnerabilities']  })">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
  @import '~@/assets/scss/_vars.scss';
  @import '~@/assets/scss/_custom.scss';
  @import '~@/assets/scss/_mixins.scss';
  .newsfeed-item {
    border: 1px solid #eaeaea;
    border-radius: 3px;

    .newsfeed-item__body {
      padding: 35px 25px;
      .body__icon {
        font-size: 32px;
        color: $focusPositive;
        margin-bottom: 10px;
      }
      .body__value {
        font-size: 24px;
        font-weight: 300;
      }
      .body__details {
        font-size: 12px;
        color: #888888;
      }
      span {
        display: block;
      }
      .divider {
        border-bottom: 1px solid #eaeaea;
        margin: 40px 0;
      }
      .wp-image {
          padding-right: 0;
          padding-left: 0;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          @include keepAspectRatio1x1;
          max-width: 180px;
          max-height: 120px;
      }
      .wp-text {
        padding-top: 20px;
      }
    }
  }

  @media screen and (max-width: 768px) {
  .newsfeed-item {
    .newsfeed-item__body {
    .wp-text {
      margin-top: 20px;
    }
    .wp-image {
      background-size: contain;
      height: 100px !important;
      max-width: 1200px;
    }
  }
}
  }

</style>

<script>
    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                hasLoaded: false,
                vulnerability: null
            }
        },

        computed: {

        },

        mounted() {
            this.load()
        },

        methods: {

            load() {
                if (!this.activity.details) {
                    return
                }

                this.vulnerability = this.activity.details

                this.hasLoaded = true
            }
        },
    }
</script>
