<template>
    <div class="overview-page">
        <div class="container-fluid">
            <div class="col-custom-row">
                <div class="col-custom-4-12 overview-top--card" :class="name"  v-for="(component, name) in visibleQuickviewComponents" :key="name">
                    <card>
                        <component :is="name" @state="setQuickviewComponentState(name, $event)"/>

                        <div class="loader loader-quickview" v-if="component.state === 'loading'">
                            <logo-loader :height="189"/>
                        </div>
                    </card>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 news-feed">
                    <card icon="pe-7s-news-paper" :headline="$t('newsFeed.headline')" :description="$t('newsFeed.description')">
                        <news-feed/>
                    </card>
                </div>

                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12" v-for="(component, name) in visibleAsideviewComponents" :key="name">
                            <card>
                                <component :is="name" @state="setAsideviewComponentState(name, $event)"/>

                                <div class="loader loader-asideview" v-if="component.state === 'loading'">
                                    <logo-loader/>
                                </div>
                            </card>
                        </div>

                        <div class="col-lg-12 integrations-overview">
                            <card icon="pe-7s-box2" :headline="$t('overview.onboarding.headline')" :description="$t('overview.onboarding.description')">
                                <integrations/>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Integrations from '@/app/onboarding/components/integrations/FullList'
  import NewsFeed from '@/app/news-feed/components/feed/NewsFeed'
  import AsideViewFacebookAd from '@/app/dashboard/components/AsideViewFacebookAd'
  import AsideViewAdsAd from '@/app/dashboard/components/AsideViewAdsAd'
  import AsideViewWebinar from '@/app/dashboard/components/AsideViewWebinar'
  import QuickviewAnalyticsVisitors from '@/app/dashboard/components/QuickviewAnalyticsVisitors'
  import QuickviewAnalyticsGoals from '@/app/dashboard/components/QuickviewAnalyticsGoals'
  import QuickviewCompetitor from '@/app/dashboard/components/QuickviewCompetitor'
  import QuickviewReputation from '@/app/dashboard/components/QuickviewReputation'
  import QuickviewInquiries from '@/app/dashboard/components/QuickviewInquiries'
  import QuickviewCustomer from '@/app/dashboard/components/QuickviewCustomer'
  import QuickviewKeyword from '@/app/dashboard/components/QuickviewKeyword'
  import QuickviewReview from '@/app/dashboard/components/QuickviewReview'

  import organization from '@/mixins/customer/organization'
  import hasIntegration from '@/mixins/integrations/hasIntegration'

  export default {
    mixins: [hasIntegration, organization],

    data () {
      return {
        quickviews: {
          'quickview-analytics-visitors': {
            state: 'loading',
            priority: 1
          },
          'quickview-inquiries': {
            state: 'loading',
            priority: 2
          },
          'quickview-review': {
            state: 'loading',
            priority: 3
          },
          'quickview-analytics-goals': {
            state: 'loading',
            priority: 4
          },
          'quickview-keyword': {
            state: 'loading',
            priority: 5
          },
			/*
          'quickview-customer': {
            state: 'loading',
            priority: 6
          },
          'quickview-competitor': {
            state: 'loading',
            priority: 7
          }, */
          'quickview-reputation': {
            state: 'loading',
            priority: 8
          }
        },
        asideviews: {
          'aside-view-facebook-ad': {
            state: 'loading',
            priority: 1
          },
          'aside-view-ads-ad': {
            state: 'loading',
            priority: 2
          }
        }
      }
    },

    computed: {
      visibleQuickviewComponents () {
        return this.visibleComponents(this.quickviews)
      },

      visibleAsideviewComponents () {
        return this.visibleComponents(this.asideviews)
      }
    },

    methods: {

      setQuickviewComponentState (component, payload) {
        this.$set(this.quickviews[component], 'state', payload)
      },

      setAsideviewComponentState (component, payload) {
        this.$set(this.asideviews[component], 'state', payload)
      },

      visibleComponents (typeOfView) {
        return Object.keys(typeOfView)
          .filter(key => {
            const component = typeOfView[key]

            return component.state !== 'failed'
          }).sort((a, b) => {
            const componentA = typeOfView[a]
            const componentB = typeOfView[b]

            return componentA.priority - componentB.priority
          }).reduce((components, key) => {
            if (Object.keys(components).length >= 3) {
              return components
            }

            components[key] = typeOfView[key]

            return components
          }, {})
      }
    },

    components: {
      NewsFeed,
      Integrations,
      QuickviewAnalyticsGoals,
      QuickviewReview,
      QuickviewAnalyticsVisitors,
      QuickviewCompetitor,
      QuickviewKeyword,
      QuickviewInquiries,
      QuickviewCustomer,
      QuickviewReputation,
      AsideViewFacebookAd,
      AsideViewAdsAd,
      AsideViewWebinar
    }
  }
</script>
