<template>
    <div>
        <div class="row head" v-if="!isSubActivity">
            <div class="col-md-6">
                <strong>{{$t('newsFeed.seo.newMaxRanking.keyword')}}</strong>
            </div>

            <div class="col-md-6 text-center hidden-xs hidden-sm">
                <strong>{{$t('newsFeed.seo.newMaxRanking.position')}}</strong>
            </div>
        </div>

        <keyword :hasLoaded="hasLoaded" :keyword="activity.details.ranking.keyword" :url="url" :rank="activity.details.ranking.rank" :showUpgoingArrow="true"></keyword>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .head {
        margin-top: 20px;
        padding: 0 15px;
    }

    .loader {
        color: white;
        line-height: 250px;
    }

    .chart-headline {
        display: block;
        color: white;
        opacity: 0.9;
        margin-left: 15px;
    }

    .chart-container {
        background: $tertiaryPositive;
        border-radius: 3px;
        margin-top: 10px;
        padding-top: 15px;
        margin-bottom: 8px;

        .chart {
            height: 250px;
            padding: 15px;
        }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import Keyword from '@/app/news-feed/components/feed/content/sub-content/Keyword'

    const KeywordService = require('@/services/seo/KeywordService')
    const RankingService = require('@/services/seo/RankingService')

    const Highcharts = require('highcharts')
    const moment = require('moment')

    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                chart: null,
                keyword: null,
                rankings: [],
                hasLoaded: false,
            }
        },

        computed: {
            isKeywordDeleted() {
                return (this.hasLoaded && !this.keyword)
            },

            chartData() {
                let chartData = []

                this.rankings.forEach((ranking) => {
                    if (!ranking.date || !ranking.date.date) {
                        return
                    }

                    const timestamp = moment(ranking.date.date).unix() * 1000

                    if (!ranking.rank) {
                        return
                    }

                    const point = [timestamp, ranking.rank]

                    chartData.push(point)
                })

                return chartData.reverse()
            },

            url() {
                if (!this.rankings.length) {
                    return ''
                }

                return this.rankings[0].url
            }
        },

        mounted() {
            this.loadKeyword()
        },

        methods: {
            loadKeyword() {
                const options = {
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'keyword',
                                    value: this.activity.details.ranking.keyword,
                                    operator: 'eq'
                                }
                            ]
                        }
                    ]
                }

                KeywordService.findAll(options, (response) => {
                    const body = response.data
                    const rows = body.rows

                    if (!rows || !rows.length) {
                        this.hasLoaded = true
                        return
                    }

                    this.keyword = rows[0]

                    this.loadRanking()
                })
            },

            loadRanking() {
                const activity = this.activity;
                const date = activity.date.date;

                const to = moment(date).add(1, 'day').format('YYYY-MM-DD')
                const from = moment(date).subtract(1, 'year').format('YYYY-MM-DD')

                const options = {
                    sort: [{
                        key: 'date',
                        direction: 'DESC',
                    }],

                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'date',
                                    value: from,
                                    operator: 'gt',
                                },
                                {
                                    key: 'date',
                                    value: to,
                                    operator: 'lt',
                                },
                            ]
                        }
                    ],
                }

                RankingService.findAll(this.keyword.id, options, (response) => {
                    const body = response.data

                    this.rankings = body.rows

                    this.hasLoaded = true

                    this.render()
                }, () => {
                    this.hasLoaded = true
                })
            },

            render() {
                const element = this.$el.querySelector('.chart')

                this.chart = new Highcharts.chart(element, {
                    credits: false,
                    exporting: { enabled: false },

                    chart: {
                        style: {
                            fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif'
                        },
                        backgroundColor: 'transparent',
                    },

                    title: {
                        text: false
                    },

                    subtitle: {
                        text: false
                    },

                    tooltip: {
                        formatter: function () {
                            return 'Din hjemmeside lå på placering <b>' + this.y +
                                '</b> den <b>' + moment(this.x).format('Do MMMM YYYY') + '</b>';
                        }
                    },

                    yAxis: {
                        reversed: true,
                        title: {
                            text: false
                        },
                        gridLineColor: 'transparent',
                        labels: {
                            style: {
                                color: 'white',
                            }
                        },
                        minTickInterval: 5,
                    },

                    xAxis: {
                        type: 'datetime',
                        lineColor: 'white',
                        labels: {
                            style: {
                                color: 'white',
                            }
                        },
                    },

                    legend: false,

                    plotOptions: {
                        series: {
                            label: {
                                connectorAllowed: false,
                            },
                        }
                    },

                    series: [{
                        name: 'Placering',
                        data: this.chartData,
                        color: 'white',
                    },],

                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                        }]
                    }

                });
            }
        },

        components: {
            Loader,
            Keyword,
        }
    }
</script>
