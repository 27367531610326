<template>
    <div>
        <div class="keyword-container" v-if="hasLoaded">
            <div class="row">
                <div class="col-md-6">
                    <i class="flag-icon flag-icon-dk pull-left"></i>
                    <strong class="keyword pull-left">{{ keyword | capitalize}}</strong>

                    <br>

                    <i class="fa fa-link fa-fw text-muted pull-left link-icon"></i>

                    <span class="text-muted pull-left url">
                        {{url}}
                    </span>
                </div>

                <div class="col-md-6 text-center hidden-xs hidden-sm">
                    <span class="text-muted no-ranking" v-if="rankMuted">{{rank}}</span>
                    <strong class="ranking" v-else>
                        {{rank}} <sup><i class="fa fa-caret-up upgoingArrow" v-if="showUpgoingArrow"></i></sup>
                    </strong>

                </div>
            </div>
        </div>

        <div class="keyword-container" v-else>
            <div class="row">
                <div class="col-md-6">
                    <i class="flag-icon flag-icon-dk pull-left"></i>
                    <strong class="keyword pull-left">{{ keyword | capitalize}}</strong>

                    <br>

                    <i class="fa fa-link fa-fw text-muted pull-left link-icon"></i>
                    <span class="text-muted pull-left url mock">http://mock.dk/loader/text</span>
                </div>

                <div class="col-md-6 text-center hidden-xs hidden-sm">
                    <strong class="ranking mock">10</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .keyword-container {
        padding: 15px;
        border-radius: 4px;
        margin-top: 8px;
    }

    .keyword,
    .flag-icon-dk {
        font-size: 18px;
        margin-bottom: 0px;
    }

    .flag-icon-dk {
        margin: 2px 10px 0 0;
    }

    .keyword {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 40px);
    }

    .link-icon {
        margin: 7px 6px 0 11px;
    }

    .url {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 35px);
        margin-top: 3px;
    }

    .ranking {
        font-size: 22px;
        margin-top: 4px;
        display: block;
        position: relative;

        .upgoingArrow {
            color: $focusSuccess;
        }
    }

    .no-ranking {
        margin-top: 12px;
        display: block;
    }
</style>

<script>
    export default {
        props: {
            hasLoaded: {
                type: Boolean,
                default: true
            },
            rank: [Number, String],
            url: String,
            keyword: String,
            rankMuted: Boolean,
            showUpgoingArrow: Boolean,
        },
    }
</script>
