<template>
    <div class="container-fluid local-stats">
        <div v-if="post" class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 local-post-image" :style="{backgroundImage: `url('${post.media}')`}"></div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 local-post-text">
                <h4 v-if="post.eventTitle">{{$t('newsFeed.localPosts.stats.title')}}{{post.eventTitle}}</h4>

                <h4 v-else>{{$t('newsFeed.localPosts.stats.titleNoPost')}}</h4>

                <p v-if="clicks" v-html="$t('newsFeed.localPosts.stats.clicksAndViews', {
                    views: views,
                    personviews: $tc('persons', views),
                    clicks: clicks,
                    personclicks: $tc('persons', clicks),
                    created: postCreated
                })"></p>

                <p v-else v-html="$t('newsFeed.localPosts.stats.onlyViews', {
                    views: views,
                    persons: $tc('persons', views),
                    created: postCreated
                })"></p>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 local-post-text">
                <h4 class="mock">Lorem ipsum</h4>
                <p class="mock">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A omnis quae reprehenderit!</p>
            </div>
        </div>

        <div class="row cta-container">
            <div class="col-lg-12">
                <router-link :to="{name: 'frontend.googleAdwords.posts.overview'}">
                    <span>{{$t('newsFeed.localPosts.stats.callToAction')}}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_custom.scss';
    @import '~@/assets/scss/_mixins.scss';

    .local-stats {
        padding: 20px 25px 0 25px;
    }

    .local-post-image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @include keepAspectRatio16x9;
        max-height: 320px;
    }
    .local-post-text {
        padding: 25px;

        h4 {
            margin: 0 0 10px 0;
        }
    }
    .cta-container {
        margin-bottom: 25px;

        div {
            text-align: center;

            span {
                padding: 5px;
                border-radius: 3px;
                text-align: center;
                margin: 20px 0 0 0;
                color: #FFA534;
                border: 1px solid #FFA534;
                cursor: default;
                display: inline-block;
            }
        }
    }

    @media screen and (max-width: 1320px) {
        .local-post-text {
            padding: 25px 0 0 0;
        }
    }
</style>

<script>
    import * as LocalPostService from '@/services/google/LocalPostService'

    const moment = require('moment')

    export default {
        props: {
            activity: Object,
        },

        data() {
            return {
                post: null,
                views: 0,
                clicks: 0,
            }
        },

        mounted() {
            this.loadPost()
            this.parseStats()
        },

        computed: {
            postId() {
                const activity = this.activity

                if (!activity.object) {
                    return ''
                }
                const object = activity.object

                return object.replace('LocalPost:' ,'')
            },

            postCreated() {
                if (!this.post) {
                    return
                }

                const post = this.post
                const created = post.created

                return moment(created.date).format('LL')
            }
        },

        methods: {
            loadPost() {
                LocalPostService.find(this.postId, (response) => {
                    this.post = response.data
                })
            },

            parseStats() {
                const activity = this.activity

                if (!activity.details) {
                    return
                }
                const details = activity.details

                if (!details.stats) {
                    return
                }
                const stats = details.stats

                if (!stats.localPostMetrics || !stats.localPostMetrics[0]) {
                    return
                }

                const metrics = stats.localPostMetrics[0]

                if (!metrics.metricValues) {
                    return
                }

                const metricValues = metrics.metricValues

                metricValues.forEach((element) => {
                    if (!element.metric || !element.totalValue) {
                        return
                    }

                    const totalValue = element.totalValue

                    if (!totalValue.value) {
                        return
                    }

                    const value = parseInt(totalValue.value)

                    switch (element.metric) {
                        case 'LOCAL_POST_VIEWS_SEARCH':
                            this.views = value
                            break;
                        case 'LOCAL_POST_ACTIONS_CALL_TO_ACTION':
                            this.clicks = value
                            break;
                    }
                })
            },
        },
    }
</script><
