<template>
    <div class="quickview-customer range-container">
        <div class="row" style="margin: 0;">
            <div class="col-lg-12">
                <p class="domain text-center" style="font-weight: 500;">{{customerDomain}}</p>
            </div>

            <div class="col-xs-6 text-center" style="height: 102px;" v-for="(metric, index) in metrics">
                <h5 class="transition">{{metric.value | numberFormat}}</h5>

                <p>
                    <small>
                        {{$t(`overview.quickview.customer.${metric.name}`)}}<br />
                        <span class="text-muted">{{metricDescription(index)}}</span>
                    </small>
                </p>
            </div>

        </div>
        <div class="row bottom-row">
                <quickview-range :pointInTime="$t('overview.today')" :cta="cta" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .range-container {
        position: relative;
        min-height: 159px;
        padding-bottom: 41px;
        .bottom-row {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 41px;
        }
    }

    .domain {
        font-size: 12px;
        color: #888;
        margin: 0;
    }

    h5 {
        font-size: 30px;
        margin-bottom: 5px;
        font-weight: 500;
    }

    p {
        font-size: 14px;
        line-height: 16px;
    }
</style>

<script>
    import QuickviewRange from '@/app/layout/components/QuickviewRange'

    import CompetitorService from '@/services/competitors/CompetitorService'
    import CustomerMetricService from '@/services/competitors/CustomerMetricService'

    import {mapGetters} from 'vuex'

    const moment = require('moment')

    export default {
        data() {
            return {
                from: moment().subtract(30, 'days'),
                to: moment().startOf('day'),
                metrics: [],
                cta: {
                    label: $t('overview.quickview.customer.cta'),
                    link: {
                        name: 'frontend.reporting.competitors'
                    }
                }
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer'
          }),

            customerDomain() {
                const identity = this.identity
                const customer = this.customer
                return customer ? customer.domain : '-'
            }
        },

        mounted() {
            this.$emit('state', 'loading')

            this.checkForExistingData()
        },

        methods: {
            checkForExistingData() {
                const options = {
                    includes: ['metricValues'],

                    sort: [
                        {
                            key: 'metricValues.created',
                            direction: 'DESC'
                        }
                    ],

                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'suggested',
                                    operator: 'eq',
                                    value: false
                                }
                            ]
                        }
                    ]
                }

                CompetitorService.findAll(options, () => {
                    this.load()
                }, error => {
                    this.$emit('state', 'failed')
                })
            },

            load() {
                if (!this.identity) {
                    return
                }

                const customer = this.customer

                if (!customer) {
                    return
                }

                CustomerMetricService.findAll({
                    sort: [
                        {
                            key: 'created',
                            direction: 'DESC'
                        }
                    ],

                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'customer',
                                    operator: 'eq',
                                    value: customer.id
                                }
                            ]
                        }
                    ],

                    group_by: ['name']
                }, response => {
                    const data = response.data
                    const rows = data.rows

                    if (!rows.length) {
                        this.$emit('state', 'failed')
                        return
                    }

                    this.metrics = rows.filter(metric => {
                        const allowedMetrics = ['organicTraffic', 'organicKeywords']
                        return allowedMetrics.includes(metric.name)
                    })

                    this.$emit('state', 'success')
                }, error => {
                    this.$emit('state', 'failed')
                })
            },

            metricDescription(index) {
                return index === 1 ? $t('overview.quickview.customer.keywordsDescription') : $t('overview.quickview.customer.trafficDescription')
            }
        },

        components: {
            QuickviewRange
        }
    }
</script>
