<template>
    <div class="asideview-webinar" >
        <div class="row">
            <div class="col-lg-12 teaser-image" style="background-image: url('images/webinar.png');">
                <div class="teaser-text text-center">
                    <h5>Google Ads for begyndere - Lær hvordan du markedsfører din virksomhed på Google</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_mixins.scss';

    .teaser-image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        &:before {
            padding-top: 30%;
            content: '';
            display: block;
        }
        .teaser-text {
            width: 100%;
            padding: 20px;
            max-height: 215px;
            h5 {
                font-size: 36px;
                font-weight: 500;
                text-shadow: 1px 1px 3px rgba(40, 40, 40, 0.8);
            }
            color: #ffffff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>

<script>
    const moment = require('moment')

    export default {

    }
</script>
