<template>
    <div class="quickview-reputation range-container" v-if="sources.length">
        <div class="row" style="margin: 0;">
            <div class="col-lg-12 relative text-center">

                <transition name="slide" mode="out-in">
                    <template v-for="(source, index) in sources" v-if="selectedSource === index">
                        <div :key="index">
                            <img :src="'images/review-sources/' + source.slug + '.png'" style="display: inline-block; float: none !important;" />
                            <p>
                                <stars :rating="source.average" />
                                <span class="text-muted">({{source.total | numberFormat}} {{$tc('overview.quickview.reputation.reviews', source.total)}})</span>
                            </p>
                        </div>
                    </template>
                </transition>

            </div>
        </div>

        <div class="row bottom-row">
                <quickview-range :pointInTime="$t('overview.today')" :cta="cta" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .slide-enter-active, .slide-leave-active {
        transition: opacity .5s ease-in-out, transform .5s ease-in-out;
    }

    .slide-enter, .slide-leave-to {
        opacity: 0;
        transform: translateY(-10px);
    }

    .slide-group {
        position: relative;
    }

    .slide-item {
        position: absolute;
    }

    img {
        width: 140px;
        margin-bottom: 10px;
    }

    span {
        i {
            color: #fac725;
        }
    }

    .relative {
        height: 102px;
        position: relative;
        padding-top: 15px;
    }

    .range-container {
        padding-bottom: 41px;
        position: relative;
        min-height: 159px;
        .bottom-row {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 15px;
            height: 41px;
        }
    }
</style>

<script>
    import Stars from '@/app/reviews/components/Stars'
    import QuickviewRange from '@/app/layout/components/QuickviewRange'
	import {mapGetters} from "vuex";

    const ReviewService = require('@/services/reviews/ReviewService')

    export default {
        data() {
            return {
                sources: [],
                interval: null,
                selectedSource: null,
                cta: {
                    label: $t('overview.quickview.reputation.cta'),
                    link: {
                        name: 'frontend.reviews'
                    }
                }
            }
        },

        watch: {
            sources() {
                if (this.interval) {
                    return
                }

                this.nextSource()
                this.interval = setInterval(this.nextSource, 5000)
            }
        },

        mounted() {
            this.$emit('state', 'loading')

            this.load()
        },

		computed: {
			...mapGetters('customer', {
				customer: 'getCustomer'
			})
		},

        destroyed() {
            if (this.interval) {
                this.interval = null
                clearInterval(this.interval)
            }
        },

        methods: {
            load() {
                const sources = ['facebook', 'trustpilot', 'google-my-business']

                sources.forEach(slug => {
                    const data = {
                        limit: 1,
                        includes: ['source'],

                        sort: [{
                            key: 'created',
                            direction: 'desc'
                        }],

                        filter_groups: [{
                            filters: [{
                                key: 'source.slug',
                                value: slug,
                                operator: 'eq'
                            }]
                        }, {
							filters: [{
								key: 'customer',
								operator: 'eq',
								value: this.customer.id
							}]
						}]
                    }

                    ReviewService.findAll(data, response => {
                        const review = response.data
                        const total = review.total

                        if (!total) {
                            this.$emit('state', 'failed')
                            return
                        }

                        ReviewService.sum('rating', data, response => {
                            const sum = response.data.total
                            const average = total ? sum / total : 0

                            this.sources.push({
                                slug: slug,
                                total: total,
                                average: average
                            })

                            this.$emit('state', 'success')
                        })
                    }, error => {
                        this.$emit('state', 'failed')
                    })
                })
            },

            nextSource() {
                const sources = this.sources

                if (!sources.length) {
                    return
                }

                if (this.selectedSource === null ||
                    this.selectedSource === sources.length - 1
                ) {
                    this.selectedSource = 0
                    return
                }

                this.selectedSource++
            }
        },

        components: {
            Stars,
            QuickviewRange
        }
    }
</script>
