<template>
    <div>
        <div class="row head" v-if="!isSubActivity">
            <div class="col-md-6">
                <strong>Søgeord</strong>
            </div>

            <div class="col-md-6 text-center hidden-xs hidden-sm">
                <strong>Nuværende placering</strong>
            </div>
        </div>

        <keyword :hasLoaded="hasLoaded" :keyword="keywordText" :url="url" :rank="rank" :rankMuted="rankMuted"></keyword>
    </div>
</template>

<style lang="scss" scoped>
    .head {
        margin-top: 20px;
        padding: 0 15px;
    }
</style>

<script>
    import Keyword from '@/app/news-feed/components/feed/content/sub-content/Keyword'

    const KeywordService = require('@/services/seo/KeywordService')
    const RankingService = require('@/services/seo/RankingService')

    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                keyword: null,
                ranking: null,
                hasLoaded: false,
                rankMuted: false,
            }
        },

        computed: {
            url() {
                const defaultValue = '-'

                if (!this.hasLoaded) {
                    return defaultValue
                }

                if (!this.keyword) {
                    return defaultValue
                }

                if (!this.ranking || this.ranking.rank > 100) {
                    return defaultValue
                }

                return this.ranking.url
            },

            rank() {
                if (!this.hasLoaded) {
                    return ''
                }

                if (!this.keyword || !this.ranking) {
                    this.rankMuted = true
                    return 'Ingen placering'
                }

                if (this.ranking && this.ranking.rank > 100) {
                    this.rankMuted = true
                    return 'Ikke i top 100'
                }

                return this.ranking.rank
            },

            keywordText() {
                if (!this.activity.details.keyword) {
                    return ''
                }

                return this.activity.details.keyword.title
            }
        },

        mounted() {
            this.loadKeyword()
        },

        methods: {
            loadKeyword() {
                const options = {
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'keyword',
                                    value: this.activity.details.keyword.title,
                                    operator: 'eq'
                                }
                            ]
                        }
                    ]
                }

                KeywordService.findAll(options, (response) => {
                    const body = response.data
                    const rows = body.rows

                    if (!rows || !rows.length) {

                        this.hasLoaded = true
                        return
                    }

                    this.keyword = rows[0]

                    this.loadRanking()
                })
            },

            loadRanking() {
                const options = {
                    limit: 1,
                    sort: [{
                        key: 'date',
                        direction: 'DESC',
                    }]
                }

                RankingService.findAll(this.keyword.id, options, (response) => {
                    const body = response.data
                    const rows = body.rows

                    this.hasLoaded = true

                    if (!rows || !rows.length) {
                        return
                    }

                    this.ranking = rows[0]
                }, () => {
                    this.hasLoaded = true
                })
            }
        },

        components: {
            Keyword
        }
    }
</script>
