<template>
  <div class="container-fluid">

      <div v-if="report" v-for="(metric, index) in metrics" class="row">
        <div v-for="value in metric" class="col-lg-4 col-md-4 col-sm-4 col-xs-12 content-container" style="text-align: center;">
            <i :class="iconForKey(value.icon)"></i>

            <span class="body__value">{{report[value.metric] | numberFormat}}</span>

            <span class="body__details">{{translationForMetric(value.metric)}}</span>
        </div>

        <div v-if="index < metrics.length - 1" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 divider"></div>
      </div>

      <div class="row cta-container">
          <div class="col-lg-12">
              <router-link :to="{name: 'frontend.googleAdwords.adwords'}">
                  <span>{{translationForKey('cta', {})}}</span>
              </router-link>
          </div>
      </div>
  </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .container-fluid {
      padding:35px 25px;
    }
    .body__icon {
        font-size: 32px;
        color: $focusPositive;
        margin-bottom: 10px;
    }
    .body__value {
        font-size: 24px;
        font-weight: 300;
    }
    .body__details {
        font-size: 12px;
        color: #888888;
    }
    span {
        display: block;
    }
    .divider {
        border-bottom: 1px solid #eaeaea;
        margin: 40px 0;
    }
    .cta-container {
        div {
          text-align: center;
          span {
            padding: 8px 16px;
            border-radius: 3px;
            text-align: center;
            margin: 40px 0 0 0;
            color: #FFA534;
            border: 1px solid #FFA534;
            cursor: default;
            display: inline-block;
          }
        }
      }

    @media screen and (max-width: 768px) {
      .content-container {
        margin-bottom: 40px;
      }
      .divider {
        margin: 0 0 40px 0;
      }
    }


</style>

<script>
    export default {
      props: {
          activity: Object,
          isSubActivity: {
              type: Boolean,
              default: false,
          },
      },

      data() {
        return {
          metrics: [
            [
              { icon: 'mouse', metric: 'clicks' },
              { icon: 'graph3', metric: 'ctr' },
              { icon: 'piggy', metric: 'avgCPC' }
            ],
            [
              { icon: 'refresh-2', metric: 'conversions' },
              { icon: 'graph3', metric: 'convRate' },
              { icon: 'piggy', metric: 'costConv' }
            ],
            [
              { icon: 'look', metric: 'impressions' },
              { icon: 'graph3', metric: 'interactionRate' },
              { icon: 'mouse', metric: 'interactions' }
            ]
          ]
        }
      },

      methods: {
        translationForMetric(metric) {
            return this.translationForKey(`metrics.${metric}`, {})
        },

        translationForKey(key, args) {
          return $t(`newsFeed.adwords.summary.${key}`, args)
        },

        iconForKey(key) {
          return `body__icon pe-7s-${key}`
        },

        filterReport(computedReport) {
          let filteredReport = computedReport

          const divider = 1000000

          const filterValues = ['avgCPC', 'avgCost', 'costConv']

          for (let i = 0; i < filterValues.length; i++) {
            const value = filterValues[i]
            const metric = filteredReport[value]

            if (metric === undefined) {
              continue
            }

            const parseMetric = parseInt(metric)

            if (parseMetric <= 0) {
              continue
            }

            const calculateMetric = parseMetric / divider

            filteredReport[value] = this.translationForKey('currency', { value: calculateMetric.toFixed(2) })
          }

          return filteredReport
        }
      },

      computed: {
        report() {
          const activity = this.activity

          if (!activity || activity === null) {
            return
          }

          const details = activity.details

          if (!details || details === null) {
            return
          }

          const report = details.report

          if (!report || report === null) {
            return
          }

          return this.filterReport(report)
        }
      },
    }
</script>
