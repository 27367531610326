<template>
    <div class="container-fluid review-missing">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 wp-image" style="background-image: url('images/feed/review/no-review.svg');"></div>

            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 wp-text">
                <p v-html="$t('newsFeed.reviews.missingReviews.subtext', {days: details.deviation})"></p>
            </div>
        </div>

        <div class="row cta-container">
            <div class="col-lg-12">
                <router-link :to="{name: 'frontend.reviews'}">
                    <span>{{$t('newsFeed.reviews.missingReviews.cta')}}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_custom.scss';
    @import '~@/assets/scss/_mixins.scss';
    .review-missing {
      padding: 10px 20px;
    }
    .body__icon {
        font-size: 32px;
        color: $focusPositive;
        margin-bottom: 10px;
    }
    .body__value {
        font-size: 24px;
        font-weight: 300;
    }
    .body__details {
        font-size: 12px;
        color: #888888;
    }
    span {
        display: block;
    }
    .divider {
        border-bottom: 1px solid #eaeaea;
        margin: 40px 0;
    }
    .wp-image {
        padding-right: 0;
        padding-left: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        @include keepAspectRatio1x1;
        max-width: 180px;
        max-height: 120px;
    }
    .wp-text {
        padding-top: 20px;
    }
    .cta-container {
        margin-bottom: 25px;

        div {
            text-align: center;

            span {
                padding: 8px 16px;
                border-radius: 3px;
                text-align: center;
                margin: 20px 0 0 0;
                color: #FFA534;
                border: 1px solid #FFA534;
                cursor: default;
                display: inline-block;
            }
        }
    }

  @media screen and (max-width: 768px) {
    .wp-text {
      margin-top: 20px;
    }
    .wp-image {
      background-size: contain;
      height: 100px !important;
      max-width: 1200px;
    }
  }
</style>

<script>
    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            details() {
                const activity = this.activity

                if (!activity || activity === null) {
                    return
                }

                const details = activity.details

                if (!details || details === null) {
                    return
                }

                return details
            }
        },
    }
</script>
