var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid local-stats"},[(_vm.post)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 local-post-image",style:({backgroundImage: ("url('" + (_vm.post.media) + "')")})}),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 local-post-text"},[(_vm.post.eventTitle)?_c('h4',[_vm._v(_vm._s(_vm.$t('newsFeed.localPosts.stats.title'))+_vm._s(_vm.post.eventTitle))]):_c('h4',[_vm._v(_vm._s(_vm.$t('newsFeed.localPosts.stats.titleNoPost')))]),(_vm.clicks)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('newsFeed.localPosts.stats.clicksAndViews', {
                views: _vm.views,
                personviews: _vm.$tc('persons', _vm.views),
                clicks: _vm.clicks,
                personclicks: _vm.$tc('persons', _vm.clicks),
                created: _vm.postCreated
            }))}}):_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('newsFeed.localPosts.stats.onlyViews', {
                views: _vm.views,
                persons: _vm.$tc('persons', _vm.views),
                created: _vm.postCreated
            }))}})])]):_c('div',{staticClass:"row"},[_vm._m(0)]),_c('div',{staticClass:"row cta-container"},[_c('div',{staticClass:"col-lg-12"},[_c('router-link',{attrs:{"to":{name: 'frontend.googleAdwords.posts.overview'}}},[_c('span',[_vm._v(_vm._s(_vm.$t('newsFeed.localPosts.stats.callToAction')))])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 local-post-text"},[_c('h4',{staticClass:"mock"},[_vm._v("Lorem ipsum")]),_c('p',{staticClass:"mock"},[_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit. A omnis quae reprehenderit!")])])}]

export { render, staticRenderFns }