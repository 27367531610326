<template>
    <div class="missed-calls">

        <div class="row">
            <div class="col-lg-12 g-align-center">
                <i class="pe-7s-call danger icon"></i>
            </div>

            <div class="col-lg-12 g-align-center">
                <strong class="danger">{{activity.details.missedCalls}} {{$t('newsFeed.inquiries.missedCalls.amountMissed')}}</strong>

                <span class="text-muted">{{$t('newsFeed.inquiries.missedCalls.period')}}</span>
            </div>
        </div>

        <div class="row cta-container">
            <div class="col-lg-12">
                <router-link :to="{name: 'frontend.reporting.conversions'}">
                    <span>{{$t('newsFeed.inquiries.missedCalls.cta')}}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .danger {
        color: $focusAlert;
    }

    .icon {
        font-size: 42px;
        padding: 15px 0 0 0;
    }

    strong {
        font-size: 18px;
        display: block;
        margin-top: 15px;
    }

    .cta-container {
        div {
          text-align: center;
          span {
            padding: 8px 16px;
            border-radius: 3px;
            text-align: center;
            margin: 40px 0 20px 0;
            color: #FFA534;
            border: 1px solid #FFA534;
            cursor: default;
            display: inline-block;
          }
        }
      }
</style>

<script>
    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>
