<template>
    <div class="feed">
        <div>
            <template v-for="result in results">
                <news-item v-if="result.discr === 'item'" :result="result"/>
                <news-group v-else-if="result.discr === 'group'" :activityGroup="result"/>
            </template>

            <div v-if="results.length === 0 && canLoadMore === false">
                <card class="noNews">
                    <strong>{{$t('newsFeed.noNews')}}</strong>
                    <p>
						{{$t('noNews.part1')}}
						{{$t('noNews.part2')}} <router-link :to="{name: 'frontend.settings.integrations'}">{{$t('noNews.part3')}}</router-link>
                        <span class="hidden-md hidden-lg"> {{$t('noNews.part4')}}</span>
                        <span class="hidden-xs hidden-sm"> {{$t('noNews.part5')}} <i class="fa fa-arrow-right"></i></span>
                    </p>
                </card>
            </div>

            <infinite-loading @infinite="load" v-if="hasLoaded && canLoadMore" :distance="250"></infinite-loading>

            <div v-if="canLoadMore && results.length != 0">
                <hr>

                <div class="text-center">
                    <a @click="load" class="load-more">{{$t('newsFeed.loadMore')}} <i class="fa fa-circle-o-notch fa-spin" v-if="!hasLoaded"></i></a>
                </div>
            </div>
        </div>

        <!-- Loader -->
        <div class="loading" v-if="!hasLoaded && results.length === 0">
            <div class="item" v-for="n in 3">
                <card>
                    <div class="content">
                        <div class="top">
                            <div class="mock-icon pull-left"></div>

                            <div class="mock">
                                Searchengineoptimaztion
                            </div>

                            <div class="mock">
                                Fetching the news feed from the SpotOn servers
                            </div>

                            <div class="clearfix"></div>
                        </div>

                        <div class="mock">
                            <hr>

                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </div>
                    </div>

                    <div class="clearfix"></div>
                </card>

                <hr>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
$imageSize: 50px;

.feed {
    .item {
        display: block;

        .image {
            float: left;
            overflow: hidden;
            width: $imageSize;
            height: $imageSize;
            background-color: #fff;
            border-radius: 50%;

            img {
                float: left;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .top {
            width: 100%;
            display: block;
            margin-bottom: 2px;

            .title {
                float: left;
                font-weight: 600;
            }

            .time {
                float: right;
                color: #606060;
                font-size: 13px;
                text-align: center;
            }
        }

        .content {
            float: left;
            width: calc(100% - 50px);

            padding: 0 0 0 25px;
        }


    }

    .load-more {
        color: #888;

        &:hover {
            text-decoration: underline;
        }
    }

    .noNews {
        strong {
            font-size: 18px;
            display: block;
            margin: 4px 0 15px;
        }
    }

    .mock-icon {
        border-radius: 99px;
        background: #ccc;
        width: 40px;
        height: 40px;
        animation: loading-blink 1s infinite;
        margin-right: 30px;
    }
}

.noNews {
    strong {
        font-size: 18px;
        display: block;
        margin: 4px 0 15px;
    }
}

.mock-icon {
    border-radius: 99px;
    background: #ccc;
    width: 40px;
    height: 40px;
    animation: loading-blink 1s infinite;
    margin-right: 30px;
}
</style>

<script>
    import {mapGetters} from "vuex";

	const FeedService = require('@/services/feed/FeedService')

    import Card from '@/app/shared/components/Card'
    import NewsItem from '@/app/news-feed/components/feed/NewsItem'
    import NewsGroup from '@/app/news-feed/components/feed/NewsGroup'
    import InfiniteLoading from 'vue-infinite-loading'
    import organization from '@/mixins/customer/organization'

    const moment = require('moment')

    export default {
        mixins: [organization],

		computed: {
			...mapGetters('customer', {
				customer: 'getCustomer'
			})
		},

        data() {
            return {
                hasLoaded: false,
                limit: 50,
                page: 1,
                results: [],
                allowedItems: {
                    seo: [
                        //'created_keyword',
                        //'new_max_ranking',
                        //'ranking_unchanged',
                        'top5_keywords',
                    ],
                    product: [
                        'product_created',
                    ],
                    facebook: [
                        //'ad_change',
                        'fb_spend_change',
                        'summary'
                    ],
                    adwords: [
                        'ga_spend_change',
                        'monthly_report',
                    ],
                    website: [
                        'session_duration',
                        'vulnerability_detected',
                        'cr_calculated',
                        'support_resolved',
                        'session_duration',
                        'breakdown_created',
                        'load_time',
                        'no_load_time_change',
                        //'no_ssl',
                        'visitor_statistics',
                        'visitors',
                        //'uptime',
                    ],
                    google_my_business: [
                        'monthly_report',
                        'vulnerability_detected',
                    ],
                    review: [
                        // 'review_created',
                        // 'missing_reviews',
                    ],
                    inquiries: [
                        'missed_calls',
                    ],
                    custom: [
                        'custom_item',
                    ],
                    local_posts: [
                        'local_posts_created',
                        'local_posts_stats',
                    ],
                },
                canLoadMore: true,
            }
        },

        mounted() {
            this.load()
        },

        methods: {

            load(round) {
                if (!round) {
                    round = 1
                }

                const options = {
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'created',
                                    operator: 'lte',
                                    value: moment().format('YYYY-MM-DD')
                                },
                                {
                                    key: 'customer',
                                    operator: 'eq',
                                    value: this.customer.id
                                }
                            ]
                        }
                    ],
                    limit: this.limit,
                    page: this.page
                }

                this.hasLoaded = false

                FeedService.activities('news', options, (response) => {
                    const body = response.data

                    body.rows.forEach((result) => {
                        const allowedItems = this.allowedItems

                        if (!allowedItems[result.actor]) {
                            return
                        }

                        if (!allowedItems[result.actor].includes(result.verb)) {
                            return
                        }

                        this.results.push(result)
                    })

                    this.hasLoaded = true

                    if (body.total <= this.page * this.limit) {
                        this.canLoadMore = false
                    }

                    this.page++
                })
            },
        },

        components: {
            NewsItem,
            NewsGroup,
            Card,
            InfiniteLoading
        }
    }
</script>
