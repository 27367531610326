<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">{{$t('newsFeed.website.visitors.source')}}</div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 content-container">{{$t('newsFeed.website.visitors.visitors')}}</div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 divider"></div>
        </div>

        <div v-for="(visitor, index) in visitors" :key="index">
            <div class="row result-container">
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 content-container">
                    <img :src="'images/feed/website/visits/source-' + visitor.visitor +'.svg'" :alt="visitor.visitor + '-logo'">
                    <p>{{translationForKey('platforms.' + visitor.visitor)}}</p>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 content-container"><p>{{visitor.visits | numberFormat}}</p></div>
          </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 divider"></div>
        </div>

        <div class="row end-result">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <p>{{translationForKey('total')}}</p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 content-container">
                <p>{{details.visits | numberFormat}}</p>
            </div>
        </div>

        <div class="row cta-container">
            <div class="col-lg-12">
                <router-link :to="{name: 'frontend.reporting.visitors'}">
                    <span>{{translationForKey('cta')}}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_custom.scss';
    @import '~@/assets/scss/_mixins.scss';

    .container-fluid {
      padding:35px 25px;
    }
    .body__icon {
        font-size: 32px;
        color: $focusPositive;
        margin-bottom: 10px;
    }
    .result-container {
        font-weight: 300;
        margin-bottom: 20px;
        p {
            margin-top: 10px;
        }
        img {
            width: 40px;
            display: inline-block;
            float: left;
            margin-right: 20px;
        }
    }
    .divider {
        border-bottom: 1px solid #eaeaea;
        margin: 20px 0;
    }
    .end-result {
        > div:first-child {
            padding-left: 75px;
        }
    }
    .logo-container {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        @include keepAspectRatio1x1;
    }
    .cta-container {
        div {
            text-align: center;
            span {
                padding: 8px 16px;
                border-radius: 3px;
                text-align: center;
                margin: 20px 0 0 0;
                color: #FFA534;
                border: 1px solid #FFA534;
                cursor: default;
                display: inline-block;
            }
        }
    }

    @media screen and (max-width: 768px) {
      .content-container {
        padding: 0;
      }
      .end-result {
        > div:first-child {
            padding-left: 15px;
        }
      }
    }
</style>

<script>
    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            translationForKey(key) {
                return $t(`newsFeed.website.visitors.${key}`)
            },
        },

        computed: {
            details() {
                const activity = this.activity

                if (!activity || activity === null) {
                    return
                }

                const details = activity.details

                if (!details || details === null) {
                    return
                }

                return details
            },

            visitors() {
                const visitors = this.details.channels
                const channels = []
                for (let index in visitors) {
                    channels.push({
                        visitor: index,
                        visits: visitors[index]
                    })
                }

                channels.sort((a, b) => {
                    return b.visits - a.visits;
                });

                return channels
            }
        },
    }
</script>
