<template>
    <div class="container-fluid uptime-container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 uptime-image" style="background-image: url('images/feed/website/no-breakdowns.svg');"></div>

            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 uptime-text">
                <p v-html="$t('newsFeed.website.uptime.body', {from: fromTime, to: toTime})"></p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_custom.scss';
    @import '~@/assets/scss/_mixins.scss';

    .uptime-container {
        padding: 15px 25px 10px 25px;
    }

    .uptime-image {
        padding: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 80% 80%;
        @include keepAspectRatio1x1;
        max-width: 180px;
        max-height: 120px;
    }
    .uptime-text {
        padding-top: 20px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1500px) {
        .uptime-image {
            width: 100%;
            max-width: 1200px;
        }
        .uptime-text {
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .uptime-image {
            max-width: 1200px;
        }
    }
</style>

<script>
    const moment = require('moment')

    export default {
        props: {
            activity: Object,
        },

        computed: {
            fromTime() {
                const activity = this.activity
                const timeFrameFrom = activity.timeFrameFrom

                return moment(timeFrameFrom.date).format('LL')
            },

             toTime() {
                const activity = this.activity
                const timeFrameTo = activity.timeFrameTo

                return moment(timeFrameTo.date).format('LL')
            }
        }
    }
</script>
