<template>
    <div class="item" v-if="activity">
        <div class="title">
            <div class="icon pull-left">
                <i :class="activity.icon"></i>
            </div>

            <div class="text pull-left">
                <strong>
                    {{isDefault ? $t('newsFeed.default.groupText', {count:activities.length}) : $t('newsFeed.groupText', {count:activities.length, actor:activity.title})}}
                </strong>

                <small class="time-mobile text-muted hidden-md hidden-lg">
                    <template v-if="activity.date.date">
                        {{activity.date.date | moment("from", "now")}}
                    </template>

                    <template v-else-if="activity.created.date">
                        {{activity.created.date | moment("from", "now")}}
                    </template>
                </small>

                <br>

                <span class="description text-muted" v-html="activity.description"></span>
            </div>

            <div class="pull-right">
                <!-- Time -->
                <div class="time pull-left text-muted hidden-xs hidden-sm">
                    <template v-if="activity.date.date">
                        {{activity.date.date | moment("from", "now")}}
                    </template>

                    <template v-else-if="activity.created.date">
                        {{activity.created.date | moment("from", "now")}}
                    </template>
                </div>

                <!-- More -->
                <div class="dropdown more">
                    <a class="dropdown-toggle" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <div class="more-icon">
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                        </div>
                    </a>

                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                        <li>
                            <a @click="hide" v-if="!hidden">
                                <span class="fa fa-eye-slash fa-fw"></span>&nbsp;
                                {{isDefault ? $t('newsFeed.default.hideText') : $t('newsFeed.hideItem', {actor: activity.title.toLowerCase()})}}
                            </a>
                            <a @click="undoHide" v-else>
                                <span class="fa fa-eye fa-fw"></span>&nbsp;
                                {{$t('newsFeed.undo')}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="clearfix"></div>

        <hr />

        <!-- Activities -->
        <transition name="slide-fade">
            <activity v-show="!hidden" :activity="activity" :isSubActivity="false"></activity>
        </transition>

        <transition name="slide-fade">
            <div class="text-center" v-show="hidden">
                {{isDefault ? $t('newsFeed.default.itemHidden') :  $t('newsFeed.itemHidden', {actor: activity.title.toLowerCase()})}}. <a @click="undoHide">{{$t('newsFeed.undo')}}</a>
            </div>
        </transition>

        <!-- Sub items -->
        <transition name="slide-fade">
            <div class="subItems" v-if="expanded">
                <template v-for="subActivity in subActivities">
                    <activity :activity="subActivity" :isSubActivity="true"></activity>
                </template>
            </div>
        </transition>

        <div class="see-all" v-if="!hidden && !expanded">
            <hr />

            <div class="text-center">
                <a @click="expand">
                    <span>{{isDefault ? $t('newsFeed.default.readMore', {count:activities.length - 1}) : $t('newsFeed.readMore', {count: activities.length - 1, actor: activity.title.toLowerCase()})}}</span>
                    <i class="fa fa-angle-down"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import Card from '@/app/shared/components/Card'
    import Activity from '@/app/news-feed/components/feed/Activity'

    const FeedService = require('@/services/feed/FeedService')
    const NewsPriorityService = require('@/services/stream/NewsPriorityService')

    const moment = require('moment')

    export default {
        props: {
            activityGroup: Object
        },

        computed: {
            isDefault() {
                return this.activity.title === ''
            },

            activities() {
                if (!this.activityGroup) {
                    return []
                }

                const activityGroup = this.activityGroup

                return activityGroup.activities
            },

            subActivities() {
                const activities = this.activities

                return activities.slice(1)
            },

            activity() {
                const activities = this.activities

                if (!activities.length) {
                    return null
                }

                return FeedService.renderSub(activities[0])
            }
        },

        data() {
            return {
                expanded: false,
                hidden: false
            }
        },

        methods: {
            expand() {
                this.expanded = true
            },

            hide() {
                const data = {
                    stream_verb: this.activity.verb,
                    stream_actor: this.activity.actor,
                    hidden: 1
                }

                NewsPriorityService.hide(data, () => {
                    this.hidden = true
                })
            },

            undoHide() {
                const data = {
                    stream_verb: this.activity.verb,
                    stream_actor: this.activity.actor,
                    hidden: 0
                }

                NewsPriorityService.hide(data, () => {
                    this.hidden = false
                })
            }
        },

        components: {
            Card,
            Activity
        }
    }
</script>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    $imageSize: 25px;

    .item {
        display: block;
        border: 1px solid #dcdcdc;
        border-radius: 5px;
        background-color: #fff;
        padding: 15px 25px;
        margin-bottom: 30px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.015);

        hr {
            margin: 14px -25px;
            border-color: #e5e5e5;
        }

        .title {
            height: 40px;

            .icon {
                font-size: 30px;
                margin-right: 15px;
                color: #9a9a9a;
            }

            .text {
                width: calc(100% - 75px);

                strong {
                    font-size: 15px;
                    color: #2b2b2b;
                    font-weight: 500;
                }

                .description {
                    font-size: 13px;
                }
            }

            .btn-link {
                padding: 11px 0 0 15px;
                border: none;
            }

            .time {
                margin-top: 20px;
                font-size: 13px;
            }

            .time-mobile {
                white-space: nowrap;
                font-size: 13px;
            }

            .more {
                display: inline-block;
                margin: 13px 0 0 15px;

                .dropdown-menu {
                    right: -14px;
                    top: 40px;
                }

                .more-icon {
                    border-radius: 3px;
                    background: #eaeaea;
                    padding: 8px 5px 5px 5px;
                    .dot {
                        background: #9a9a9a;
                        width: 3px;
                        height: 3px;
                        display: block;
                        margin-bottom: 3px;
                        border-radius: 99px;
                    }
                }
            }
        }

        .dropdown-menu {
            border-radius: 4px;
            border-color: #dedede;
            box-shadow: 0 0 30px #ccc;

            &:before {
                border-bottom-color: #dedede;
            }
        }

        .see-all a {
            color: initial;
        }
    }

    @media (min-width: $screen-md-min) {
        .item .title .text {
            width: calc(100% - 190px);
        }
    }

    #reportModal .modal-content {
        overflow: hidden;
        border-radius: 8px;

        .close {
            margin-right: 7px;
        }
    }
</style>
