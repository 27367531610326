<template>
    <div class="support_resolved">
        <div class="row">
            <div class="col-md-6">
                <p>Status for supportsag</p>
                <strong class="solved">Løst</strong>
            </div>

            <div class="col-md-6">
                <p>Ansvarlig</p>

                <strong class="responsible" v-if="hasLoaded && supportTicket">
                    <div class="image-container" v-if="responsiblePhoto">
                        <img :src="responsiblePhoto">
                    </div>

                    <span class="name">{{responsibleFirstName}}</span>
                </strong>

                <strong class="responsible" v-if="hasLoaded && !supportTicket">
                    -
                </strong>

                <strong class="responsible" v-if="!hasLoaded">
                    <span class="name mock">Lorem</span>
                </strong>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    p {
        margin: 0;
    }

    strong {
        font-size: 20px;
        margin-bottom: 10px;
        display: block;

        &.solved {
            color: $focusSuccess;
        }

        &.responsible {
            .image-container {
                width: 34px;
                height: 26px;
                overflow: hidden;
                display: inline-block;

                img {
                    width: 38px;
                    margin-top: -10px;
                }
            }
        }
    }
</style>

<script>
    const SupportTicketService = require('@/services/support-tickets/SupportTicketService')

    export default {
        props: {
            activity: Object,
            isSubActivity: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                hasLoaded: false,
                supportTicket: null
            }
        },

        computed: {
            supportTicketId() {
                if (!this.activity.object) {
                    return ''
                }

                return this.activity.object.replace('SupportTicket:', '')
            },

            responsibleFirstName() {
                if (!this.supportTicket || !this.supportTicket.responsible) {
                    return
                }

                const names = this.supportTicket.responsible.name.split(' ')

                if (!names.length) {
                    return
                }

                return names[0]
            },

            responsiblePhoto() {
                if (!this.supportTicket || !this.supportTicket.responsible) {
                    return
                }

                const responsible = this.supportTicket.responsible

                if (!responsible.photo) {
                    return
                }

                return '/images/employees/' + this.supportTicket.responsible.photo
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                const options = {
                    includes: ['responsible']
                }

                SupportTicketService.find(this.supportTicketId, options, response => {
                    this.supportTicket = response.data

                    this.hasLoaded = true
                }, error => {
                    this.hasLoaded = true
                })
            }
        }
    }
</script>
